import React, { useState,useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import Nav from 'react-bootstrap/Nav';
import NavDropdown from 'react-bootstrap/NavDropdown';
import { clearLocalStorageData } from '../../Utilities/Functions';
import { APIShowResearcherImage } from '../../Utilities/APIS';

function HorizontalHeader() {
    // const {user} = useContext(UserContext);
    const navigate = useNavigate();
    const [userName, setUserName] = useState('');
    const [userProfileImage, setUserProfileImage] = useState(null);
    



    useEffect(() => {
        const user = localStorage.getItem('UserDetails');
        if (user) {
            const jsonData = JSON.parse(user)
            let userName = jsonData.name;
            let profileImage = jsonData.profileImage;
            setUserName(userName);
            setUserProfileImage(profileImage);

        }
    }, []);

    const logout = () => {
        clearLocalStorageData();
        navigate('/');
    };


    return (
        <>
            {/* <Nav variant="pills" activeKey="1" onSelect={handleSelect}> */}
            <div id="horizontal-menu">
                <Nav variant="pills" activeKey="1"  >
                    <Nav.Item>
                        <Nav.Link eventKey="1"  as={Link} to="/dashboard">
                            Dashboard
                        </Nav.Link>
                    </Nav.Item> &nbsp;&nbsp;

                    <NavDropdown title="Research" id="nav-dropdown">
                        <NavDropdown.Divider />
                        <NavDropdown.Item eventKey="4.1" className='navigation-labels'>
                            <Link to="/submit"> Submit</Link>
                        </NavDropdown.Item>
                        <NavDropdown.Divider />
                        <NavDropdown.Item eventKey="4.1" className='navigation-labels'>
                            <Link to="/researches"> Researches</Link>
                        </NavDropdown.Item>
                        <NavDropdown.Divider />
                        <NavDropdown.Item eventKey="4.1" className='navigation-labels'>
                            <Link to="/researchers"> Researchers</Link>
                        </NavDropdown.Item>
                        <NavDropdown.Divider />
                    </NavDropdown>
                    
                    <NavDropdown title="Projects" id="nav-dropdown">
                        <NavDropdown.Divider />
                        <NavDropdown.Item eventKey="4.1" className='navigation-labels'>
                            <Link to="/projectsSubmit"> Submit</Link>
                        </NavDropdown.Item>
                        <NavDropdown.Divider />
                        <NavDropdown.Item eventKey="4.1" className='navigation-labels'>
                            <Link to="/projectsProjects" >Projects</Link>
                        </NavDropdown.Item>
                        <NavDropdown.Divider />
                        <NavDropdown.Item eventKey="4.1" className='navigation-labels'>
                            <Link to="/institutions">Institutions</Link>
                        </NavDropdown.Item>
                        <NavDropdown.Divider />
                    </NavDropdown>

                    <NavDropdown title="Others" id="nav-dropdown">
                        <NavDropdown.Divider />
                        <NavDropdown.Item eventKey="4.1" className='navigation-labels'>
                            <Link to="/institutions">Institutions</Link>
                        </NavDropdown.Item>
                        <NavDropdown.Divider />
                        <NavDropdown.Item eventKey="4.1" className='navigation-labels'>
                            <Link to="/themes">Themes</Link>
                        </NavDropdown.Item>
                        <NavDropdown.Divider />
                    </NavDropdown>
                </Nav>
                <div className='user-profile-div'>
                    <div className='my-grid-container-2-columns'>
                        <div className='my-grid-item-user'>
                            <img src={APIShowResearcherImage+userProfileImage} alt="Profile" className=" user-icon" />
                        </div>
                        <div className='my-grid-item-user'>
                        <NavDropdown title={userName} id="nav-dropdown">
                            <NavDropdown.Item eventKey="4.2" as={Link} to="/profile" >Profile</NavDropdown.Item>
                            <NavDropdown.Divider />
                            <NavDropdown.Item eventKey="4.1" className='navigation-labels' onClick={logout} >Log Out </NavDropdown.Item>
                            <NavDropdown.Divider />
                        </NavDropdown>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default HorizontalHeader;