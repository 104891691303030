

import React,{Component} from 'react';
import axios from 'axios';
import { useNavigate, Link } from 'react-router-dom';
// redux 
import { connect } from 'react-redux';

import '../Utilities/css/main.css';
import '../Utilities/assets/bootstrap/css/bootstrap.min.css';
import '../Utilities/assets/css/style.css';
import { APIResearcherGetByEmail, AUTH_TOKEN } from '../Utilities/APIS';
import { fetchResearchAreasData, fetchResearchesData, fetchUniversitiesData } from '../Actions/actions';
// import Logo from "../Utilities/imgs/logo1.png";

const headers = {'Authorization': `Bearer ${AUTH_TOKEN}`};

class LogIn extends Component {
    
constructor(props){
    
    super(props);
    this.state = {
        UserEmail:'',
        UserPassword:'',
        NoRecordsFound:'',
        CredentialMissMatch:'',
        UserResearch:[],
        // NavigateToDashboard:false,


        ShowAxiosErrorAlert:false,
        AxiosErrorMessage:'',
        AxiosErrorResponse:'',
        AllInputsRequiredError:'',
    }    
}

componentDidMount(){

}

setEmailTextInput = (text) =>{this.setState({UserEmail:text.target.value})}
setPasswordTextInput = (text) =>{this.setState({UserPassword:text.target.value})}

// getUserData = (userId) =>{
//     console.log("userId ++ "+userId);
//     console.log(APIResearcherGetById+userId)
//     console.log("======> "+this.state.UserResearch)
//     axios.get(APIResearcherGetById+userId)
//     .then(res => {
//         let results =JSON.stringify(res.data);
//         let jsonResults =JSON.parse(results); 
//         let demo = {"name":"kamoga"}
//         this.setState({UserResearch:demo})
//         console.log("state ======> "+this.state.UserResearch)
//         this.goToDashboardScreen();
//         })
//     .catch(err=>{console.log(err);})
// }

goToDashboardScreen = (userId,userName) =>{
    let dashboardProps = {
        userId:userId,
        userName:userName,
    };
    this.props.navigate("/dashboard",{ state: dashboardProps });
}

logInUser = async () =>{
    // const {setUserContext} = this.context;

    let email = this.state.UserEmail;
    let password = this.state.UserPassword

    try{
        const postRequest = await axios.get(APIResearcherGetByEmail+email,{headers})
            
        let results = postRequest.data;
        let jsonString = JSON.stringify(results)
        let jsonResults =JSON.parse(jsonString);

        if (results.length === 0)
        {let msg = "Sorry !! No Records  Found "; this.setState({NoRecordsFound:msg})}
        else
        {
            
            let DbEmail = jsonResults.email;
            let DbPassword  = jsonResults.password;
            if ((email === DbEmail)&&(password === DbPassword ))
                {
                    let userName= jsonResults.fullName;
                    let userEmail = jsonResults.email;
                    let userTitle = jsonResults.title;
                    let userUniversity = jsonResults.university;
                    let userContact = jsonResults.contact;
                    let userId = jsonResults.id;
                    let userNationality = jsonResults.nationality;
                    let userAbout= jsonResults.about;
                    let userLevel= jsonResults.level;
                    let userTwitter= jsonResults.twitter;
                    let userFacebook= jsonResults.facebook;
                    let userInstagram= jsonResults.instagram;
                    let userLinkedin= jsonResults.linkedin;
                    let profileImage = jsonResults.profileImage;


                    let Details = {name:userName,profileImage:profileImage,linkedin:userLinkedin,instagram:userInstagram,facebook:userFacebook,twitter:userTwitter,level:userLevel,about:userAbout, nationality:userNationality,email:userEmail,contact:userContact,title:userTitle, university:userUniversity,id:userId,loggedIn:true};
                    localStorage.setItem('UserDetails',JSON.stringify(Details));
                    this.props.navigate("/dashboard");
                }
            else
            {let msg = "Sorry !!  Invalid Credentials Email Or Password Try Again"; this.setState({CredentialMissMatch:msg})}
            
        }
    }
    catch (error){
        console.log(error)
        this.setState({AxiosErrorMessage:error.message});
        this.setState({AxiosErrorResponse:error.request.response});
        this.setState({ShowAxiosErrorAlert:true});
    }
}
        
guestLogIn = () =>{
    let Details = {loggedIn:false};
    localStorage.setItem('UserDetails',JSON.stringify(Details));
    this.props.navigate("/dashboard");
}
render() {
const {UserEmail,UserPassword,NoRecordsFound,CredentialMissMatch} =this.state;
return (
        <main className="main-content  mt-0">
            <div className="page-header align-items-start min-vh-100">
                <span className="mask bg-gradient-dark opacity-6"></span>
                    <div className="container my-auto">
                        <div className="row">
                            <div className="col-lg-4 col-md-8 col-12 mx-auto">
                                <br/><br/><br/><br/>
                                <div className="card z-index-0 fadeIn3 fadeInBottom">
                                    <div className="card-body">
                                        <center>
                                            {/* <br/>
                                            <img src={Logo} alt='logo-icon' className="logo-icon" /> */}
                                            <br/>
                                            <h4><b>Welcome Back!</b></h4>
                                            <h5 className='custom-red-color'><b>Research and Innovations Academy</b></h5>
                                            <h5 className='custom-red-color' ><b>RIA</b></h5>
                                            <h6><b>Log in to Continue</b></h6>
                                        </center>
                                        {this.state.ShowAxiosErrorAlert ?(<>
                                            <div className="alert alert-danger bg-danger text-light border-0 alert-dismissible fade show" role="alert">
                                                <p>{this.state.AllInputsRequiredError}</p>
                                                <p>{this.state.AxiosErrorMessage}</p>
                                                <p>{this.state.AxiosErrorResponse}</p>
                                            </div>
                                        </>):(<></>)}
                                        <div>
                                            <p className="primary-text" >Email</p>
                                            <input name="email" className="form-control form-inputs" autoComplete="off" placeholder="User Email"
                                                type="text"
                                                value={UserEmail}
                                                onChange={text => this.setEmailTextInput(text)}
                                            />
                                        </div><br/>

                                        <div>
                                            <p className="primary-text" >Password</p>
                                            <input  className="form-control form-inputs" autoComplete="off" placeholder="User Password"
                                                type="password"
                                                value={UserPassword}
                                                onChange={text => this.setPasswordTextInput(text)}
                                            />
                                        </div><br/>
                                        <div>
                                            <p className="primary-text red-text" >{NoRecordsFound}</p>
                                            <p className="primary-text red-text" >{CredentialMissMatch}</p>
                                        </div>
                                        <Link to="/forget-password">
                                            <label className="form-check-label mb-0 ms-3" > 
                                            Forgot Password  !  &nbsp;&nbsp;</label><span><b><u> Click Here</u></b></span><br/>
                                        </Link><br/>

                                            {/* <label className="form-check-label mb-0 ms-3" > 
                                            Forgot Password  !  &nbsp;&nbsp;</label><span><b><u> Click Here</u></b></span><br/> */}

                                        <Link to="/register">
                                            <label className="form-check-label mb-0 ms-3" > 
                                            Don’t have account ? &nbsp;&nbsp;</label><span><b><u>Register Now</u></b></span><br/>
                                        </Link><br></br>

                                        <br/>
                                        <div className="text-center">
                                        <button onClick={()=>this.logInUser()} className="btn btn-primary custom-bg-primary custom-text-white" style={{width:"80%"}}> Log in </button>

                                        <br/><br/>
                                        <center><p><b>-- OR --</b></p></center>
                                        <button onClick={()=>this.guestLogIn()} className="btn btn-success custom-bg-primary custom-text-white" style={{width:"80%"}}> Log In  As A Guest</button>
                                        </div><br/>

                                    </div>
                                </div>
                            </div>
                        </div>
                </div>
            </div>
        </main>
);
}}

function withNavigation(Component) {
    return function WrappedComponent(props) {
        const navigate = useNavigate();
        return <Component {...props} navigate={navigate} />;
    };
}



// Wrap the class component with the functional component to get access to navigate
export default withNavigation(LogIn);