

import React,{Component} from 'react';
import axios from 'axios';
import {  Link, useNavigate } from 'react-router-dom';
import ProgressBar from 'react-bootstrap/ProgressBar';
import Button from 'react-bootstrap/Button';
import ButtonGroup from 'react-bootstrap/ButtonGroup';
import Tab from 'react-bootstrap/Tab';
import Alert from 'react-bootstrap/Alert';
import Tabs from 'react-bootstrap/Tabs';
import '../../Utilities/css/main.css';
import '../../Utilities/css/w3.css';
import '../../Utilities/assets/bootstrap/css/bootstrap.min.css';
import '../../Utilities/assets/css/style.css';
import { APIProjectsPost, APIResearchersList,AUTH_TOKEN} from '../../Utilities/APIS';
import { getTodaysDate,  } from '../../Utilities/Functions';
import GuestUser from '../GuestUser';
import LockedTab from '../LockedTab';
import NavigationMenu from '../Navigation/NavigationMenu';


const headers = {'Authorization': `Bearer ${AUTH_TOKEN}`};

class Submit extends Component {
    
constructor(props){
    
    super(props);
    this.state = {
        delaySeconds:2000,
        showPostSuccessAlert:false,
        showSubmitBtn:true,
        showPostErrorAlert:false,
        showContinueToResearchBtn:false,
        showRequiredAttributesAlert:false,
        showPostingAnimation:false,
        postSuccessMsg:'',
        postErrorMsg:'',
        SimilaritiesErrorMsg:'',

        userLoggedIn:false,
        userName:'',
        userId:'',
        userUniversity:'',


        AreasData:[],
        
        

        completedValue:'100 %',
        ProjectTitle:'',
        ProjectMembersInputs:[{data:''}],
        ProjectFundersInputs:[{data:''}],
        ProjectDescription:'', ProjectEndPeriod:'', ProjectStartPeriod:'',
        NoSimilaritiesInResults:'',
        NoSimilaritiesUploadedResearch:'',

        // Tab Activations
        showResearchStatusTab:false,
        showSummaryPreviewTab:false,
        showSimilaritiesTab:false,
        showSubmitTab:false,


        // Loading 
        ShowAxiosErrorAlert:false,
        AxiosErrorMessage:'',
        AxiosErrorResponse:'',
        AllInputsRequiredError:'',

    }    
}


componentDidMount(){this.loadData(); this.loadUser();this.initializeReduxData();}

loadUser = ()=>{
    const user = localStorage.getItem('UserDetails');
    if (user) {
        const jsonData = JSON.parse(user)
        let userName = jsonData.name;
        let userId = jsonData.id;
        let userUniversity = jsonData.university;
        let loggedIn = jsonData.loggedIn;

        this.setState({userName:userName});
        this.setState({userLoggedIn:loggedIn});
        this.setState({userId:userId});
        this.setState({userUniversity:userUniversity});

    }
}

initializeReduxData = () =>{
    const storedState = localStorage.getItem('reduxState');
    if (storedState) {
        // const parsedState = JSON.parse(storedState);
        // this.setState({ AreasData: parsedState.sliceProjectTitles.ProjectTitlesData});
    }
};

loadData = () =>{

    axios.get(APIResearchersList,{headers})
    .then(res => {
        let results =JSON.stringify(res.data);
        let jsonResults =JSON.parse(results); 
        this.setState({ResearchersData:jsonResults})
        })
    .catch(err=>{console.log(err);})
}

setProjectTitleTextInput = (text) =>{this.setState({ProjectTitle:text.target.value}); setTimeout(()=>{this.displaySummaryPreviewTab()},this.state.delaySeconds)}
setProjectDescriptionTextInput = (text) =>{this.setState({ProjectDescription:text.target.value}); setTimeout(()=>{this.displaySummaryPreviewTab()},this.state.delaySeconds)}
setProjectEndPeriodTextInput = (text) =>{this.setState({ProjectEndPeriod:text.target.value}); setTimeout(()=>{this.displaySummaryPreviewTab()},this.state.delaySeconds)}
setProjectStartPeriodTextInput = (text) =>{this.setState({ProjectStartPeriod:text.target.value}); setTimeout(()=>{this.displaySummaryPreviewTab()},this.state.delaySeconds)}

setProjectMembersTextInputs = (InputsArray) =>{this.setState({ProjectMembersInputs:InputsArray})}
setProjectFundersTextInputs = (InputsArray) =>{this.setState({ProjectFundersInputs:InputsArray})}


displaySummaryPreviewTab = () =>{
    let title = this.state.ProjectTitle;

    if ( title.length === 0)
        {
            this.setState({showSummaryPreviewTab:false});
            this.setState({showSimilaritiesTab:false});
            this.setState({showSubmitTab:false});
        }
    else{
            this.setState({showSummaryPreviewTab:true});
            this.setState({showSimilaritiesTab:true});
            this.setState({showSubmitTab:true});
        }
}



handleAdd = (currentInputs,inputsToSet) =>
    {
        // console.log("Adding..");
        const newInputs = [...currentInputs];
        newInputs.push('');

        if(inputsToSet === 'ProjectMembers'){this.setProjectMembersTextInputs(newInputs)}
        else if(inputsToSet === 'ProjectFunders'){this.setProjectFundersTextInputs(newInputs)}

    }
handleDelete = (currentInputs,inputsToSet) =>
    {
        // console.log("Deleting..");
        const newInputs = [...currentInputs];
        newInputs.pop();
        if(inputsToSet === 'ProjectMembers'){this.setProjectMembersTextInputs(newInputs)}
        else if(inputsToSet === 'ProjectFunders'){this.setProjectFundersTextInputs(newInputs)}
    }
handleTextInputChange = (text,index,currentInputs,inputsToSet) =>
    {
        // console.log("Handling Changes..");
        // console.log(".."+JSON.stringify(text));
        const newInputs = [...currentInputs];
        newInputs[index]={data:text.target.value};
        if(inputsToSet === 'ProjectMembers'){this.setProjectMembersTextInputs(newInputs)}
        else if(inputsToSet === 'ProjectFunders'){this.setProjectFundersTextInputs(newInputs)}
    }


postUserResearch =  async () => 
    {
        this.setState({showPostingAnimation:true});
        // const {user} = this.context;

        // console.log("=======> Positing ")
        let uploadDate = getTodaysDate();
        let researcher = this.state.userId;
        let university = this.state.userUniversity;
        let title = this.state.ProjectTitle;
        let description = this.state.ProjectDescription;
        let start = this.state.ProjectStartPeriod;
        let end = this.state.ProjectEndPeriod;
        let ProjectMembersList = JSON.stringify(this.state.ProjectMembersInputs);
        let ProjectFundersList = JSON.stringify(this.state.ProjectFundersInputs);


        if ( title.length === 0)
            {this.setState({showRequiredAttributesAlert:true});}
        else
        {
            const payload = {
                "uploadDate":uploadDate,
                "projectTitle":title,
                "researcher":researcher,
                "projectDescription":description,
                "university":university,
                "projectStart":start,
                "projectEnd":end,
                "projectMembers":ProjectMembersList, 
                "projectFunders":ProjectFundersList, 
            };

            // console.log(JSON.stringify(payload))
            try
            {
                const postRequest = await axios.post(APIProjectsPost,payload,{headers})
                
                let result = postRequest;
                console.log(result.data)

                this.setState({postSuccessMsg:result.data});
                this.setState({showPostSuccessAlert: true});
                this.setState({showContinueToResearchBtn:true});
                this.setState({showPostingAnimation:false});
                this.setState({showPostErrorAlert:false});
                this.setState({showSubmitBtn:false});

                // setTimeout(()=>{ this.props.navigate("/researches");},5000);
            }
            catch (error)
                {
                    this.setState({postErrorMsg:error.message});
                    this.setState({showPostSuccessAlert: false}); 
                    this.setState({showPostErrorAlert:true});
                }
        }
    }

render() {
    const {ProjectDescription,ProjectEndPeriod,ProjectStartPeriod,ProjectMembersInputs,ProjectFundersInputs,userLoggedIn,completedValue,showPostErrorAlert,showPostingAnimation,
            ProjectTitle,showContinueToResearchBtn,showPostSuccessAlert,
        } =this.state;

return (
        <div className='body-container'>
            <NavigationMenu/>
            <div className='inner-body-container'>
                <div className="alert alert-success bg-success text-light border-0 alert-dismissible" role="alert">
                    <strong>Submit Your Project</strong>  Today !!!!
                    <div className="custom-alert-btn-actions">
                        <ButtonGroup className="mb-2">
                            <Link to={"/projectsSubmit"} >
                                <Button className='black-bg-color' >
                                    Projects Uploads
                                </Button>
                            </Link>
                        </ButtonGroup> 
                    </div>
                </div>
                {this.state.ShowAxiosErrorAlert ?(<>
                        <div className="alert alert-danger bg-danger text-light border-0 alert-dismissible fade show" role="alert">
                            <p>{this.state.AllInputsRequiredError}</p>
                            <p>{this.state.AxiosErrorMessage}</p>
                            <p>{this.state.AxiosErrorResponse}</p>
                        </div>
                    </>):(<></>)}
                <div className="row">
                    <div >
                    <div className='card'>
                    <Tabs defaultActiveKey="ResearchHit" id="justify-tab-example" className="mb-2" justify>
                        <Tab eventKey="ResearchHit" title="Project-Overview">
                            <section className="section">
                            <div className="row">
                                <div className="col-lg-6">
                                    <div className="card">
                                        <div className="card-body">
                                            <h5 className="card-title">Project Details </h5>
                                                <label className="primary-text"><b>Title</b></label>
                                                <input style={{width:"80%"}} className="form-control form-inputs"
                                                    value={ProjectTitle} placeholder='Project Title'
                                                    onChange={text => this.setProjectTitleTextInput(text)}
                                                />

                                                <br></br>
                                                <label className="primary-text"><b>Description</b></label>
                                                <textarea style={{width:"80%"}}  className="form-control form-inputs file-uploads-inputs" placeholder='Project Description'
                                                value={ProjectDescription}
                                                onChange={text => this.setProjectDescriptionTextInput(text)}
                                                >

                                                </textarea>

                                                <br></br>
                                                <div class='projects-date-div'>
                                                    <div className='my-grid-item'>
                                                        <label className="primary-text"><b>Start Period</b></label>
                                                        <input type="date"  style={{width:"50%"}}  className="form-control form-inputs" 
                                                            value={ProjectStartPeriod}
                                                            onChange={text => this.setProjectStartPeriodTextInput(text)}
                                                        />
                                                    </div>

                                                    <div className='my-grid-item'>
                                                        <label className="primary-text"><b>End Period</b></label>
                                                        <input type="date"  style={{width:"50%"}}  className="form-control form-inputs file-uploads-inputs" 
                                                            value={ProjectEndPeriod}
                                                            onChange={text => this.setProjectEndPeriodTextInput(text)}
                                                        />
                                                    </div>
                                                </div>

                                        </div>
                                    </div>
                                </div>

                                    <div className="col-lg-6">
                                        <div className="card">
                                            <div className="card-body">
                                                <h5 className="card-title">Other Details</h5>
                                                <label className="primary-text"><b>Principal Investigator (PIs)</b></label>
                                                {ProjectFundersInputs.map((item,index)=>(
                                                    <div key={index}>
                                                        <input type="text" style={{width:"65%"}} className="form-control form-inputs" placeholder="Copy & Paste Main Objectives.."
                                                        value={item.text}
                                                        onChange={text => this.handleTextInputChange(text,index,this.state.ProjectFundersInputs,'ProjectFunders')}
                                                        />
                                                        <br/>
                                                    </div>
                                                ))}
                                                <br/>
                                                    <button type="button" className='btn btn-primary' onClick={()=>this.handleAdd(this.state.ProjectFundersInputs,'ProjectFunders')}>
                                                        Add New Input
                                                    </button>
                                                    &nbsp;&nbsp;&nbsp;
                                                    <button type="button" className='btn btn-danger' onClick={()=>this.handleDelete(this.state.ProjectFundersInputs,'ProjectFunders')}>
                                                        Remove Input
                                                    </button>
                                                <br></br><br></br>

                                                <label className="primary-text"><b>Project / Research Team</b></label>
                                                {ProjectMembersInputs.map((item,index)=>(
                                                    <div key={index}>
                                                        <input type="text" style={{width:"65%"}}  className="form-control form-inputs" placeholder="Copy & Paste Paragraph.."
                                                        value={item.text}
                                                        onChange={text => this.handleTextInputChange(text,index,this.state.ProjectMembersInputs,'ProjectMembers')}
                                                        />
                                                        <br/>
                                                    </div>
                                                ))}
                                                <button type="button" className='btn btn-primary' onClick={()=>this.handleAdd(this.state.ProjectMembersInputs,'ProjectMembers')}>
                                                    Add New Input
                                                </button>
                                                &nbsp;&nbsp;&nbsp;
                                                <button type="button" className='btn btn-danger' onClick={()=>this.handleDelete(this.state.ProjectMembersInputs,'ProjectMembers')}>
                                                    Remove Input
                                                </button>

                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </section>
                        </Tab>
                        
                        <Tab eventKey="Summary-Preview" title="Summary Preview">
                            {this.state.showSummaryPreviewTab ? (<>
                                <div className=''>
                                    <div className="w3-panel w3-border w3-pale-blue w3-leftbar w3-rightbar w3-border-blue w3-round-large">
                                        <br/>
                                        <p className='research-preview-title primary-text'>Author</p>
                                        <p className='primary-text research-preview-paragraph'>{this.state.userName}</p>

                                        <p className='research-preview-title primary-text'>Title</p>
                                        <p className='primary-text research-preview-paragraph'>{ProjectTitle}</p>
                                        <br/>

                                        <p className='research-preview-title primary-text'>Description</p>
                                        <p className='primary-text research-preview-paragraph'>{ProjectDescription}</p>
                                        <br/>

                                        <p className='research-preview-title primary-text'>Start Period : {ProjectStartPeriod}</p>
                                        <p className='research-preview-title primary-text'>End Period : {ProjectEndPeriod}</p>
                                        <br/>

                                        <p className='research-preview-title primary-text'>Project Funders</p>
                                        {ProjectFundersInputs && ProjectFundersInputs.map((item,index)=>(
                                            <div key={index}>
                                                <div >
                                                    <p className='primary-text research-preview-paragraph'>{item.data}</p>
                                                </div>
                                            </div>
                                        ))}
                                        <br/>
                                        <p className='research-preview-title primary-text'>Other Members</p>
                                        {ProjectMembersInputs && ProjectMembersInputs.map((item,index)=>(
                                            <div key={index}>
                                                <div >
                                                    <p className='primary-text research-preview-paragraph'>{item.data}</p>
                                                </div>
                                            </div>
                                        ))}
                                        <br/>
                                    </div>
                                </div>
                            </>):(<><LockedTab/></>)}
                        </Tab>
                        <Tab eventKey="Submit" title="Submit">
                            {this.state.showSubmitTab ?(<>
                                <br/>
                                <div className='tab-research-body'>
                                    {userLoggedIn?(<>
                                        
                                        
                                            <section className="section">
                                                <div className="row">
                                                        <div className="col-lg-6">
                                                            <div className="card">
                                                                <div className="card-body">
                                                                <h5 className="card-title text-primary">Project Progress </h5>
                                                                <p className='text-primary'>Completed</p>
                                                                    <ProgressBar animated now={100} label={completedValue} />
                                                                    <br/><br/>
                                                                </div>
                                                            </div>
                                                        </div>

                                                        <div className="col-lg-6">
                                                            <div className="card">
                                                                <div className="card-body">
                                                                <h5 className="text-success card-title">Submit Your Project</h5>
                                                                <p className='text-success'>Submit Your Research</p>
                                                                {this.state.showSubmitBtn ? (<>
                                                                    <button type="button" className='btn btn-success'  onClick={()=>this.postUserResearch()}>
                                                                        Submit Project
                                                                    </button>  &nbsp;&nbsp;
                                                                </>):(<></>)}
                                                                    
                                                                    {showContinueToResearchBtn ?(<>
                                                                        <Link to={"/projectsProjects"} >
                                                                            <button type="button" className='btn btn-success'>
                                                                                Continue To Projects
                                                                            </button>
                                                                        </Link>
                                                                    </>):(<></>)}
                                                                    <br/><br/>
                                                                    {this.state.showRequiredAttributesAlert ?(<> 
                                                                        <Alert variant="primary">
                                                                            <Alert.Heading>Required Attributes **</Alert.Heading>
                                                                                <p>1. Project Title </p>
                                                                            <hr />
                                                                            <p className="mb-0">The Mentioned Above Are Required </p>
                                                                        </Alert><br></br>
                                                                    </>) :(<> </>)}
                                                                    {showPostingAnimation ? (<>
                                                                        <center>
                                                                        <div className="spinner-border text-danger" role="status">
                                                                            <span className="visually-hidden posting-spinner" >Loading...</span>
                                                                        </div>
                                                                        <p className='text-danger'><b>... Posting Your  Project ...</b></p>
                                                                    </center>
                                                                    </>):(<></>)}
                                                                    {showPostSuccessAlert ?(<> 
                                                                        <Alert variant="primary">
                                                                            <Alert.Heading>Response</Alert.Heading>
                                                                            <p>{this.state.postSuccessMsg}</p>
                                                                            <hr />
                                                                            <p className="mb-0">Thank You For Uploading Your Project !!</p>
                                                                        </Alert><br></br>
                                                                    </>) :(<> </>)}
                                                                    {showPostErrorAlert ?(<> 
                                                                        <Alert variant="danger">
                                                                            <Alert.Heading>Response</Alert.Heading>
                                                                            <p>{this.state.postErrorMsg}</p>
                                                                            <hr />
                                                                            <p className="mb-0">Try Again Uploading Your Project </p>
                                                                        </Alert><br></br>
                                                                    </>) :(<> </>)}
                                                                </div>
                                                            </div> 
                                                        </div>
                                                    </div>
                                            </section>
                                    </>):(<><GuestUser/><br/><br/> </>)}
                                </div>
                            </>):(<><LockedTab/></>)}
                        </Tab>
                    </Tabs>
                    </div>
                    </div>
                </div>
                </div>
        </div>

);
}}

function withNavigation(Component) {
    return function WrappedComponent(props) {
        const navigate = useNavigate();
        return <Component {...props} navigate={navigate} />;
    };
}

// Wrap the class component with the functional component to get access to navigate
export default withNavigation(Submit);