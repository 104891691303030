import {configureStore, applyMiddleware} from "@reduxjs/toolkit";
import { thunk } from "redux-thunk";
import rootReducer from "../Reducers/rootReducer";

// save state to local storage

const saveState = (state) =>{
    try{
        const serializedState = JSON.stringify(state);
        localStorage.setItem('reduxState',serializedState);
        // console.error('Saved Redux State',serializedState);
    }
    catch(error){console.log("Could Not Save State :: "+ error);console.error('Could not save state', error);}
};

// Load State
const loadState = () =>{
    try {
        const serializedState = localStorage.getItem('reduxState');
        if (serializedState === null) {
            return undefined;
        }
        return JSON.parse(serializedState);
    } catch (error) {
        console.error('Could not load state', error);
        return undefined;
    }
};


// Load the state from localStorage
const persistedState = loadState();

// const store = configureStore({reducer:rootReducer},applyMiddleware(thunk));
const store = configureStore({reducer:rootReducer, persistedState},applyMiddleware(thunk));


// Subscribe to store updates and save to localStorage
store.subscribe(() => {
    saveState(store.getState());
});


export default store;