

import React,{Component} from 'react';
import axios from 'axios';
// import ReactPaginate from 'react-paginate';
// redux
// import { connect } from 'react-redux';
import {  Link} from 'react-router-dom';
import ButtonGroup from 'react-bootstrap/ButtonGroup';
import Button from 'react-bootstrap/Button';
import Dropdown from 'react-bootstrap/Dropdown';

import withNavigate from './withNavigate';
import '../Utilities/css/main.css';
import '../Utilities/assets/bootstrap/css/bootstrap.min.css';
import '../Utilities/assets/css/style.css';
import NextIcon from "../Utilities/imgs/next.png"
import { APIResearchersList, APIResearchesGet, AUTH_TOKEN } from '../Utilities/APIS';
import { daysPassed, getIdCategory, getIdFullName } from '../Utilities/Functions';
import NavigationMenu from './Navigation/NavigationMenu';

const headers = {'Authorization': `Bearer ${AUTH_TOKEN}`};

class ViewsViewMore extends Component {
    
constructor(props){
    
    super(props);
    this.state = {
        ViewData:[],
        PaginateFilteredData:[],
        ResearchersData:[],
        AreasData:[],
        researchStatus:'',

        // pagination
        recordsPerPage:10,
        pageNumber:0,


        // Loading 
        ShowAxiosErrorAlert:false,
        AxiosErrorMessage:'',
        AxiosErrorResponse:'',
        AllInputsRequiredError:'',
        

    }    
}

componentDidMount() {
    this.loadData();
    this.initializeReduxData();
    // setTimeout(()=>this.loadData(),3000);

    // setTimeout(()=>this.setPaginateFilteredData(),3000);
}

initializeReduxData = () =>{
    const storedState = localStorage.getItem('reduxState');
    if (storedState) {
        const parsedState = JSON.parse(storedState);
        this.setState({ AreasData: parsedState.sliceResearchAreas.ResearchAreasData});
    }
};

loadData = () =>{

    axios.get(APIResearchesGet,{headers})
    .then(res => {
            let results =JSON.stringify(res.data);
            let jsonResults =JSON.parse(results); 
            this.setState({FilteredData:jsonResults})
            this.setState({ResearchesData:jsonResults})
        })
    .catch(error=>{console.log(error);
        this.setState({AxiosErrorMessage:error.message});
        this.setState({AxiosErrorResponse:error.request.response});
        this.setState({ShowAxiosErrorAlert:true});
    })

    axios.get(APIResearchersList,{headers})
    .then(res => {
            let results =JSON.stringify(res.data);
            let jsonResults =JSON.parse(results); 
            this.setState({ResearchersData:jsonResults})
        })
    .catch(error=>{console.log(error);
        this.setState({AxiosErrorMessage:error.message});
        this.setState({AxiosErrorResponse:error.request.response});
        this.setState({ShowAxiosErrorAlert:true});
    })


    const { location} = this.props;
    const { state } = location;
    const { ViewData ,userName,researchStatus} = state || {};
    this.setState({ViewData:ViewData});
    this.setState({userName:userName});
    this.setState({researchStatus:researchStatus});

}
goToViewerDetailsScreen = (researchViewer,pdfFile,viewDate) =>{
    let viewerProps = {
        researchViewer:researchViewer,
        pdfFile:pdfFile,
        viewDate:viewDate
    };

    this.props.navigate("/viewer",{ state: viewerProps });
}
setPaginateFilteredData = () =>{
    this.setState({PaginateFilteredData:this.state.ResearchViews.filter((item, index) => {return (index >= this.state.pageNumber * this.state.recordsPerPage) & (index < (this.state.pageNumber + 1) * this.state.recordsPerPage);})})
}

setPage = (e)=>{
    console.log(e)
    this.setState({pageNumber:e}); 
    this.setPaginateFilteredData();
    console.log("pageNumber"+this.state.pageNumber)

}


displayRecordsData = () =>{

    // const Data = this.state.PaginateFilteredData;
    return(
            this.state.ViewData.map((item,index)=>(
                <div key={index}>
                    <div className="card research-display-card">
                        <div className="card-header">
                            <p className="log-green-text">{getIdFullName(item.researchViewer, this.state.ResearchersData)}  </p>
                            <div  className="card-details-div">
                                <table>
                                    <tbody>
                                        <tr>
                                            <td>
                                                <p className="log-green-text details-label">Details</p>
                                            </td>
                                            <td>
                                                <img  src={NextIcon} alt='action btn img' className='card-next-icon'
                                                    onClick={()=>this.goToViewerDetailsScreen(item.researchViewer,item.fileName,item.viewDate)}/>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                        <div className="card-body">
                            <p className='text-black'>{item.researchTopic} </p>
                            <div  className="card-days-div">
                            <span className='log-green-text'>{daysPassed(item.viewDate)} days ago</span>
                            </div>
                            <p className='text-black' >Status {item.researchStatus} &nbsp;&nbsp;&nbsp;&nbsp;Research Area  | <b>{getIdCategory(item.researchArea,this.state.AreasData)}</b>&nbsp;&nbsp;&nbsp;&nbsp; Viewed Date | <b>{item.viewDate}</b> &nbsp;&nbsp;&nbsp;&nbsp;Uploaded Date | <b>{item.uploadDate}</b></p>
                        </div>
                    </div>
                </div>
            ))
    )
}


render() {
    const {AreasData} =this.state;

return (
            <div className='body-container'>
                <NavigationMenu/>
                <div className='inner-body-container'>
                    <div className="alert alert-success bg-success text-light border-0 alert-dismissible" role="alert">
                        <strong className='strong-text white-text' > Research User Views   </strong><span className='strong-text white-text'>View</span>
                        <div className="custom-alert-btn-actions">
                            <ButtonGroup className="mb-2">
                                <Link to={"/dashboard"} >
                                    <Button className='black-bg-color' >
                                        Dashboard
                                    </Button>
                                </Link>
                            </ButtonGroup> 
                        </div>
                    </div>
                    <div className="row">
                        <div className='drop-down-researches-div'>
                            <Dropdown>
                                <Dropdown.Toggle variant="primary" id="dropdown-basic">
                                    Select Research Area
                                </Dropdown.Toggle>

                                <Dropdown.Menu>
                                    {AreasData && AreasData.map((item, index)=>(
                                        <Dropdown.Item key={index} onClick={()=>this.filterDataByArea(item.id)}>
                                            <label  > {item.name}</label>
                                        </Dropdown.Item>
                                    ))}
                                </Dropdown.Menu>
                            </Dropdown>
                        </div><br></br><br></br>
                        <div className="col-12">
                            <div className="card recent-sales overflow-auto">
                                <div className="card-body">
                                    {this.displayRecordsData()}
                                    {/* <ReactPaginate 
                                        previousLabel={"Previous"}
                                        nextLabel={"Next"}
                                        pageCount={Math.ceil(this.state.ResearchViews.length / this.state.recordsPerPage)}
                                        onPageChange={(event) => this.setPage(event.selected)}

                                        containerClassName={"paginationButtons"}
                                        previousClassName={"previousButton"}
                                        nextLinkClassName={"nextButton"}
                                        disabledClassName={"paginationDisabled"}
                                        activeClassName={"paginationActive"}
                                    /> */}
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </div>

);
}}


export default withNavigate(ViewsViewMore);