import { FETCH_UNIVERSITIES_DATA_FAILURE, FETCH_UNIVERSITIES_DATA_REQUEST, FETCH_UNIVERSITIES_DATA_SUCCESS } from "../Actions/actions";

const initialState = {UniversitiesData:[],error:null};


const universitiesReducer = (state=initialState,action)=>{
    switch(action.type){
        case FETCH_UNIVERSITIES_DATA_REQUEST:
            return{...state, error:null}
        case FETCH_UNIVERSITIES_DATA_SUCCESS:
            return{...state,UniversitiesData:action.payload}
        case FETCH_UNIVERSITIES_DATA_FAILURE:
            return {...state,error:action.payload}
        default:return state;
    }
}

export default universitiesReducer;