import { APP_AUTH_TOKEN, APP_URL } from "./Env";


var BaseUrl = APP_URL;
export const AUTH_TOKEN = APP_AUTH_TOKEN; 



// Researcher
export const APIResearcherCreate = BaseUrl+ "/api/ria/researcher/create";
export const APIEmailsList = BaseUrl+"/api/ria/researcher/list/emails";
export const APIUpdateResearcherDetails = BaseUrl+"/api/ria/researcher/details/update";
export const APIUpdateResearcherPassword = BaseUrl+"/api/ria/researcher/password/update";
export const APIUpdateResearcherImage = BaseUrl+"/api/ria/researcher/profile/image/update";
export const APIUploadResearcherImage = BaseUrl+"/api/ria/researcher/profile/image/upload";
export const APIShowResearcherImage = BaseUrl+"/api/ria/researcher/profile/image/file/";
export const APIResearcherGetByEmail = BaseUrl+"/api/ria/researcher/list/by/email/";
export const APIResearcherForgotPassword = BaseUrl+"/api/ria/researcher/forgot/password";
export const APIResearcherPasswordRest = BaseUrl+"/api/ria/researcher/password/reset";
export const APIResearcherById = BaseUrl+"/api/ria/researcher/list/researcher/by/id/";
export const APIResearcherGetById = BaseUrl+"/api/ria/research/by/researcherName/";

// Universities 
export const APIUniversitiesGet = BaseUrl+"/api/ria/universities/list/all";

// Areas 
export const APIAreasGet = BaseUrl+"/api/ria/areas/list/all";

// Researches 
export const APIResearchesGet = BaseUrl+"/api/ria/research/list/all";
export const APIResearchersList = BaseUrl+"/api/ria/researcher/list/all";
export const APIResearchSimilarity = BaseUrl+"/api/ria/research/check/similarity";
export const APIResearchPost = BaseUrl+"/api/ria/research/create";
export const APIResearchUpdateText = BaseUrl+"/api/ria/research/update/text";
export const APIResearchUpdatePdf = BaseUrl+"/api/ria/research/update/pdf";
export const APIResearchPdfFile = BaseUrl+"/api/ria/research/pdf/file/";
export const APIResearchPdfTextList = BaseUrl+"/api/ria/research/list/pdf/plain/text";


// Research Views
export const APIResearchViewsPost = BaseUrl+"/api/ria/research/views/create";
export const APIResearchViewsByName = BaseUrl+"/api/ria/research/views/by/researcherName/";


// Projects 
export const APIProjectsPost = BaseUrl+"/api/ria/project/create";
export const APIProjectsList = BaseUrl+"/api/ria/project/list/all";
export const APIProjectsListByName = BaseUrl+"api/ria/project/by/researcherName/";

