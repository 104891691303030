import { APIAreasGet,APIUniversitiesGet, APIResearchesGet, AUTH_TOKEN,} from "../Utilities/APIS";

const headers = {'Authorization': `Bearer ${AUTH_TOKEN}`};

// fetching data
export const FETCH_RESEARCH_AREAS_DATA_REQUEST = "FETCH_RESEARCH_AREAS_DATA_REQUEST";
export const FETCH_UNIVERSITIES_DATA_REQUEST = "FETCH_UNIVERSITIES_DATA_REQUEST";
export const FETCH_RESEARCHER_DATA_REQUEST = "FETCH_RESEARCHER_DATA_REQUEST";
export const FETCH_RESEARCHES_DATA_REQUEST = "FETCH_RESEARCHES_DATA_REQUEST";
export const FETCH_RESEARCHERS_DATA_REQUEST = "FETCH_RESEARCHERS_DATA_REQUEST";

// success feed backs 
export const FETCH_RESEARCH_AREAS_DATA_SUCCESS = "FETCH_RESEARCH_AREAS_DATA_SUCCESS";
export const FETCH_UNIVERSITIES_DATA_SUCCESS = "FETCH_UNIVERSITIES_DATA_SUCCESS";
export const FETCH_RESEARCHER_DATA_SUCCESS = "FETCH_RESEARCHER_DATA_SUCCESS";
export const FETCH_RESEARCHES_DATA_SUCCESS = "FETCH_RESEARCHES_DATA_SUCCESS";
export const FETCH_RESEARCHERS_DATA_SUCCESS = "FETCH_RESEARCHERS_DATA_SUCCESS";

// failure feed back
export const FETCH_RESEARCH_AREAS_DATA_FAILURE = "FETCH_RESEARCH_AREAS_DATA_FAILURE";
export const FETCH_UNIVERSITIES_DATA_FAILURE = "FETCH_UNIVERSITIES_DATA_FAILURE";
export const FETCH_RESEARCHER_DATA_FAILURE = "FETCH_RESEARCHER_DATA_FAILURE";
export const FETCH_RESEARCHES_DATA_FAILURE = "FETCH_RESEARCHES_DATA_FAILURE";
export const FETCH_RESEARCHERS_DATA_FAILURE = "FETCH_RESEARCHERS_DATA_FAILURE";



// UNIVERSITIES actions 
export const fetchUniversitiesDataRequest = ()=> ({type:FETCH_UNIVERSITIES_DATA_REQUEST});
export const fetchUniversitiesDataSuccess = (data)=> ({type:FETCH_UNIVERSITIES_DATA_SUCCESS,payload:data});
export const fetchUniversitiesDataFailure = (error)=> ({type:FETCH_UNIVERSITIES_DATA_FAILURE,payload:error});

// AREAS actions
export const fetchResearchAreasDataRequest = ()=> ({type:FETCH_RESEARCH_AREAS_DATA_REQUEST});
export const fetchResearchAreasDataSuccess = (data)=> ({type:FETCH_RESEARCH_AREAS_DATA_SUCCESS,payload:data});
export const fetchResearchAreasDataFailure = (error)=> ({type:FETCH_RESEARCH_AREAS_DATA_FAILURE,payload:error});

// USER actions
export const fetchResearcherDataRequest = ()=> ({type:FETCH_RESEARCHER_DATA_REQUEST});
export const fetchResearcherDataSuccess = (data)=> ({type:FETCH_RESEARCHER_DATA_SUCCESS,payload:data});
export const fetchResearcherDataFailure = (error)=> ({type:FETCH_RESEARCHER_DATA_FAILURE,payload:error});

// RESEARCHES actions
export const fetchResearchesDataRequest = ()=> ({type:FETCH_RESEARCHES_DATA_REQUEST});
export const fetchResearchesDataSuccess = (data)=> ({type:FETCH_RESEARCHES_DATA_SUCCESS,payload:data});
export const fetchResearchesDataFailure = (error)=> ({type:FETCH_RESEARCHES_DATA_FAILURE,payload:error});

// RESEARCHERS actions
export const fetchResearchersDataRequest = ()=> ({type:FETCH_RESEARCHERS_DATA_REQUEST});
export const fetchResearchersDataSuccess = (data)=> ({type:FETCH_RESEARCHERS_DATA_SUCCESS,payload:data});
export const fetchResearchersDataFailure = (error)=> ({type:FETCH_RESEARCHERS_DATA_FAILURE,payload:error});


const err = "There Was An Error :: ";
export const fetchUniversitiesData = () =>{
    return (dispatch)=>{
        dispatch(fetchUniversitiesDataRequest());
        fetch(APIUniversitiesGet,{headers})
            .then((response)=>response.json())
            .then((data)=>{dispatch(fetchUniversitiesDataSuccess(data))})
            .catch((error)=>{
                console.log(err, error);
                dispatch(fetchUniversitiesDataFailure(error))
            });
    };
}; 

export const fetchResearchAreasData = () =>{
    return (dispatch)=>{
        dispatch(fetchResearchAreasDataRequest());
        fetch(APIAreasGet,{headers})
            .then((response)=>response.json())
            .then((data)=>{dispatch(fetchResearchAreasDataSuccess(data))})
            .catch((error)=>{
                console.log(err, error)
                dispatch(fetchResearchAreasDataFailure(error))
            });
    };
};

// export const fetchResearcherData = () => {
//     return (dispatch) =>{
//         dispatch(fetchResearcherDataRequest());
//         fetch(APIResearcherGet)
//             .then((response)=>response.json())
//             .then((data)=>{dispatch(fetchResearcherDataSuccess(data))})
//             .catch((error)=>{
//                 console.log(err,error)
//                 dispatch(fetchResearcherDataFailure(error))
//             });
//     }
// };

export const fetchResearchesData = () =>{
    console.log("fetchResearchesData Called "+APIResearchesGet)
    return (dispatch)=>{
        dispatch(fetchResearchesDataRequest());
        fetch(APIResearchesGet,{headers})
            .then((response)=>response.json())
            .then((data)=>{dispatch(fetchResearchesDataSuccess(data))})
            .catch((error)=>{
                console.log(err,error)
                dispatch(fetchResearchesDataFailure(error))
            });
    };

};