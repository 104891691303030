import { combineReducers } from "@reduxjs/toolkit";

import researchAreasReducer from "./researchAreasReducer";
import universitiesReducer from "./universitiesReducer";
import researchesReducer from "./researchesReducer";


const rootReducer = combineReducers({
    sliceUniversities:universitiesReducer,
    sliceResearchAreas:researchAreasReducer,
    sliceResearches:researchesReducer,
});

export default rootReducer;