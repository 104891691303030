

import React,{Component} from 'react';
import axios from 'axios';
import { Link, useNavigate } from 'react-router-dom';
import Accordion from 'react-bootstrap/Accordion';
import ProgressBar from 'react-bootstrap/ProgressBar';
import Button from 'react-bootstrap/Button';
import ButtonGroup from 'react-bootstrap/ButtonGroup';
import Tab from 'react-bootstrap/Tab';
import Alert from 'react-bootstrap/Alert';
import Tabs from 'react-bootstrap/Tabs';
import '../Utilities/css/main.css';
import '../Utilities/css/w3.css';
import '../Utilities/assets/bootstrap/css/bootstrap.min.css';
import '../Utilities/assets/css/style.css';
import { APIResearchersList, APIResearchesGet, APIResearchPdfTextList, APIResearchPost, APIResearchSimilarity, AUTH_TOKEN} from '../Utilities/APIS';
import { getIdCategory, getIdFullName, getTodaysDate,  } from '../Utilities/Functions';
import GuestUser from './GuestUser';

import * as pdfjsLib from 'pdfjs-dist/build/pdf';
import Base64ToPdf from './Base64ToPdf';
import CustomProgressBar from './CustomProgressBar';
import LockedTab from './LockedTab';
import NavigationMenu from './Navigation/NavigationMenu';
// Set up the worker for pdfjs
// https://cdnjs.com/libraries/pdf.js/4.6.82
pdfjsLib.GlobalWorkerOptions.workerSrc = 'https://cdnjs.cloudflare.com/ajax/libs/pdf.js/4.6.82/pdf.worker.mjs';

const headers = {'Content-Type': 'multipart/form-data','Authorization': `Bearer ${AUTH_TOKEN}`};


class Submit extends Component {
    
constructor(props){
    
    super(props);
    this.state = {
        delaySeconds:2000,
        showPostSuccessAlert:false,
        showSubmitBtn:true,
        showPostErrorAlert:false,
        showContinueToResearchBtn:false,
        showRequiredAttributesAlert:false,
        showPostingAnimation:false,
        postSuccessMsg:'',
        postErrorMsg:'',
        SimilaritiesErrorMsg:'',

        userLoggedIn:false,
        userName:'',
        userId:'',
        userUniversity:'',


        AreasData:[],
        ResearchArea:'',
        file: null,
        pdfFilePreview:null,
        pdfFileName:null,
        ResearcherPdfFile:'',
        fileName:'',
        fileType:'',
        base64URL: "",
        ResearchTopic:'',
        ResearchStatusTextInput:'',
        ResearchProjectTextInput:'',
        AbstractInputs:[{data:''}],
        HypothesisInputs:[{data:''}],
        MainObjectivesInputs:[{data:''}],
        SpecificObjectivesInputs:[{data:''}],
        ResearchResultsInputs:[{data:''}],
        RecommendationsInputs:[{data:''}],
        ConclusionsInputs:[{data:''}],
        TheoreticalFrameworkInputs:[{data:''}],
        LiteratureReviewInputs:[{data:''}],
        MethodologyInputs:[{data:''}],
        DiscussionOfResultsInputs:[{data:''}],

        completedValue:'100 %',
        pdfPlainText:'',
        stringsToCompare:[],
        pdfPlainTextError:'',
        pdfPlainTextSuccess:'',
        ResearchSimilarities:[],
        ResearchesToCompareTo:[],
        ResearchersData:[],

        NoSimilaritiesInResults:'',
        NoSimilaritiesUploadedResearch:'',

        // Tab Activations
        showResearchStatusTab:false,
        showSummaryPreviewTab:false,
        showSimilaritiesTab:false,
        showSubmitTab:false,


    }    
}


componentDidMount(){this.loadData(); this.loadUser();this.initializeReduxData();}

loadUser = ()=>{
    const user = localStorage.getItem('UserDetails');
    if (user) {
        const jsonData = JSON.parse(user)
        let userName = jsonData.name;
        let userId = jsonData.id;
        let userUniversity = jsonData.university;
        let loggedIn = jsonData.loggedIn;

        this.setState({userName:userName});
        this.setState({userLoggedIn:loggedIn});
        this.setState({userId:userId});
        this.setState({userUniversity:userUniversity});

    }
}

initializeReduxData = () =>{
    const storedState = localStorage.getItem('reduxState');
    if (storedState) {
        const parsedState = JSON.parse(storedState);
        this.setState({ AreasData: parsedState.sliceResearchAreas.ResearchAreasData});
    }
};

loadData = () =>{
    axios.get(APIResearchesGet,{headers})
    .then(res => {
        let results =JSON.stringify(res.data);
        let jsonResults =JSON.parse(results); 
        // console.log("results"+results)
        const plainTextArray = [];
        const researchesToCompareTo = [];
        const pdfPlainTextArray = [];
        jsonResults.map((item, index)=>{
            let originalString = item.pdfPlainText;
            let researcherName = item.researcherName;
            let uploadDate = item.uploadDate;
            let pdfFile = item.pdfFile

            if (originalString !== null && typeof originalString === 'string') {
                plainTextArray.push(originalString); 

                let data = {researcherName:researcherName, uploadDate:uploadDate,pdfFile :pdfFile }
                researchesToCompareTo.push(data);
            } 
            else {
                console.log('The variable is null or not a string.'); 

            }


        });
        pdfPlainTextArray.push(...plainTextArray) 

        console.log("pdfPlainTextArray"+ JSON.stringify(pdfPlainTextArray))
        console.log("pdfPlainTextArray Len :: " + pdfPlainTextArray.length)

        this.setState({stringsToCompare:pdfPlainTextArray});
        this.setState({ResearchesToCompareTo:researchesToCompareTo});
        })
    .catch(err=>{console.log(err);})
    axios.get(APIResearchersList,{headers})
    .then(res => {
        let results =JSON.stringify(res.data);
        let jsonResults =JSON.parse(results); 
        this.setState({ResearchersData:jsonResults})
        })
    .catch(err=>{console.log(err);})
}

setResearchTopic = (text) =>{this.setState({ResearchTopic:text.target.value}); setTimeout(()=>{this.setState({showResearchStatusTab:true})},this.state.delaySeconds);}
setResearchAreaTextInput = (text) =>{this.setState({ResearchArea:text.target.value}); setTimeout(()=>{this.displaySummaryPreviewTab()},this.state.delaySeconds)}
setResearchStatusTextInput = (text) =>{this.setState({ResearchStatusTextInput:text.target.value});setTimeout(()=>{this.displaySummaryPreviewTab()},this.state.delaySeconds)}
setResearchProjectTextInput = (text) =>{this.setState({ResearchProjectTextInput:text.target.value}); setTimeout(()=>{this.displaySummaryPreviewTab()},this.state.delaySeconds)}
setAbstractTextInputs = (InputsArray) =>{this.setState({AbstractInputs:InputsArray})}
setHypothesisTextInputs = (InputsArray) =>{this.setState({HypothesisInputs:InputsArray})}
setMainObjectivesTextInputs = (InputsArray) =>{this.setState({MainObjectivesInputs:InputsArray})}
setSpecificObjectivesTextInputs = (InputsArray) =>{this.setState({SpecificObjectivesInputs:InputsArray})}
setResearchResultsTextInputs = (InputsArray) =>{this.setState({ResearchResultsInputs:InputsArray})}
setRecommendationsTextInputs = (InputsArray) =>{this.setState({RecommendationsInputs:InputsArray})}
setConclusionsTextInputs = (InputsArray) =>{this.setState({ConclusionsInputs:InputsArray})}
setTheoreticalFrameworkTextInputs = (InputsArray) =>{this.setState({TheoreticalFrameworkInputs:InputsArray})}
setLiteratureReviewTextInputs = (InputsArray) =>{this.setState({LiteratureReviewInputs:InputsArray})}
setMethodologyTextInputs = (InputsArray) =>{this.setState({MethodologyInputs:InputsArray})}
setDiscussionOfResultsTextInputs = (InputsArray) =>{this.setState({DiscussionOfResultsInputs:InputsArray})}
setResearcherPdfFile = (pdfFile) =>{this.setState({ResearcherPdfFile:''});this.setState({ResearcherPdfFile:pdfFile});}


displaySummaryPreviewTab = () =>{
    let file = this.state.base64URL;
    let status = String(this.state.ResearchStatusTextInput);
    let area = parseInt(this.state.ResearchArea);
    let project = String(this.state.ResearchProjectTextInput);

    if ( file.length === 0 || status.length === 0 || area.length === 0 || project.length === 0)
        {
            this.setState({showSummaryPreviewTab:false});
            this.setState({showSimilaritiesTab:false});
            this.setState({showSubmitTab:false});
        }
    else{
            this.setState({showSummaryPreviewTab:true});
            this.setState({showSimilaritiesTab:true});
            this.setState({showSubmitTab:true});
        }
}


PdfTextExtractor = async (event) => {
    const file = event.target.files[0];
    if (file) {
        const fileReader = new FileReader();

        // Read the file as a data URL (base64)
        fileReader.onload = async () => {
            try {
            // Extract the base64 string from the data URL
            const base64String = fileReader.result.split(',')[1];

            // Convert base64 to Uint8Array
            const binaryString = window.atob(base64String);
            const len = binaryString.length;
            const uint8Array = new Uint8Array(len);
            for (let i = 0; i < len; i++) {
                uint8Array[i] = binaryString.charCodeAt(i);
            }

            // Load the PDF document
            const pdf = await pdfjsLib.getDocument(uint8Array).promise;
            let extractedText = '';

            // Loop through each page and extract text
            for (let pageNumber = 1; pageNumber <= pdf.numPages; pageNumber++) {
                const page = await pdf.getPage(pageNumber);
                const textContent = await page.getTextContent();
                
                // Aggregate the text items into plain text
                const pageText = textContent.items.map((item) => item.str).join(' ');
                // extractedText += pageText + '\n';
                extractedText += pageText;
            }

            // Set the extracted text to the state
            this.setState({pdfPlainText :extractedText});
            // console.log("PDF plain text Content "+ extractedText)
            this.setState({pdfPlainTextSuccess:"Success In Processing PDF File TEXT"});
            // check for the similarities in research from the server 
            setTimeout(()=>{ this.postUserResearchSimilarity()},3000);
            } catch (err) {
            // console.error(err);
            let response = "Error processing PDF file TEXT " + err.message
            this.setState({pdfPlainTextError:response});
            }
        };

        // Read the file as a data URL (base64 string)
        fileReader.readAsDataURL(file);
        } 
        else {console.log('Please upload a valid file.');
    }
};

handlePdfFilePreview = (event) => {
    const file = event.target.files[0];
    if (file && file.type === 'application/pdf') {
      this.setState({pdfFilePreview:URL.createObjectURL(file)}); // Create a URL for the selected PDF file
        console.log("Preview URL "+ URL.createObjectURL(file))
    } else {
        alert('Please select a valid PDF file');
    }
};
handleFile(e) { 
    this.PdfTextExtractor(e);
    this.handlePdfFilePreview(e)
    // Getting the files from the input 
    let fileName = e.target.files[0].name; 
    let fileSize = e.target.files[0].size; 
    let file = e.target.files[0]; 

    this.setState({pdfFileName: file});
    this.setState({fileName: fileName});
    this.setState({fileSize: fileSize});

    this.getBase64(file)
        .then(result => {
            file["base64"] = result;
            // console.log("File Is", file);
            const base64WithoutPrefix = result.substr('data:application/pdf;base64,'.length);
            // this.setState({base64URL: result,file});
            this.setState({base64URL: base64WithoutPrefix});
            setTimeout(()=>{this.displaySummaryPreviewTab()},this.state.delaySeconds);
        })
        .catch(err => {
            console.log(err);
        });
} 

getBase64 = file => {
    return new Promise(resolve => {
        // let fileInfo;
        let baseURL = "";
        // Make new FileReader
        let reader = new FileReader();

        // Convert the file to base64 text
        reader.readAsDataURL(file);

        // on reader load somthing...
        reader.onload = () => {
            // Make a fileInfo Object
            // console.log("Called", reader);
            baseURL = reader.result;
            // console.log(baseURL);
            resolve(baseURL);
        };
        // console.log(fileInfo);
    });
    };

handleAdd = (currentInputs,inputsToSet) =>
    {
        // console.log("Adding..");
        const newInputs = [...currentInputs];
        newInputs.push('');

        if(inputsToSet === 'Abstract'){this.setAbstractTextInputs(newInputs)}
        else if(inputsToSet === 'Hypothesis'){this.setHypothesisTextInputs(newInputs)}
        else if(inputsToSet === 'MainObjectives'){this.setMainObjectivesTextInputs(newInputs)}
        else if(inputsToSet === 'SpecificObjectives'){this.setSpecificObjectivesTextInputs(newInputs)} 
        else if(inputsToSet === 'ResearchResults'){this.setResearchResultsTextInputs(newInputs)}
        else if(inputsToSet === 'Recommendations'){this.setRecommendationsTextInputs(newInputs)}
        else if(inputsToSet === 'Conclusions'){this.setConclusionsTextInputs(newInputs)}
        else if(inputsToSet === 'TheoreticalFramework'){this.setTheoreticalFrameworkTextInputs(newInputs)}
        else if(inputsToSet === 'LiteratureReview'){this.setLiteratureReviewTextInputs(newInputs)}
        else if(inputsToSet === 'Methodology'){this.setMethodologyTextInputs(newInputs)}
        else if(inputsToSet === 'DiscussionOfResults'){this.setDiscussionOfResultsTextInputs(newInputs)}

    }
handleDelete = (currentInputs,inputsToSet) =>
    {
        // console.log("Deleting..");
        const newInputs = [...currentInputs];
        newInputs.pop();
        if(inputsToSet === 'Abstract'){this.setAbstractTextInputs(newInputs)}
        else if(inputsToSet === 'Hypothesis'){this.setHypothesisTextInputs(newInputs)}
        else if(inputsToSet === 'MainObjectives'){this.setMainObjectivesTextInputs(newInputs)}
        else if(inputsToSet === 'SpecificObjectives'){this.setSpecificObjectivesTextInputs(newInputs)}
        else if(inputsToSet === 'ResearchResults'){this.setResearchResultsTextInputs(newInputs)}
        else if(inputsToSet === 'Recommendations'){this.setRecommendationsTextInputs(newInputs)}
        else if(inputsToSet === 'Conclusions'){this.setConclusionsTextInputs(newInputs)}   
        else if(inputsToSet === 'TheoreticalFramework'){this.setTheoreticalFrameworkTextInputs(newInputs)}
        else if(inputsToSet === 'LiteratureReview'){this.setLiteratureReviewTextInputs(newInputs)}
        else if(inputsToSet === 'Methodology'){this.setMethodologyTextInputs(newInputs)}
        else if(inputsToSet === 'DiscussionOfResults'){this.setDiscussionOfResultsTextInputs(newInputs)}
    }
handleTextInputChange = (text,index,currentInputs,inputsToSet) =>
    {
        // console.log("Handling Changes..");
        // console.log(".."+JSON.stringify(text));
        const newInputs = [...currentInputs];
        newInputs[index]={data:text.target.value};
        if(inputsToSet === 'Abstract'){this.setAbstractTextInputs(newInputs)}
        else if(inputsToSet === 'Hypothesis'){this.setHypothesisTextInputs(newInputs)}
        else if(inputsToSet === 'MainObjectives'){this.setMainObjectivesTextInputs(newInputs)}
        else if(inputsToSet === 'SpecificObjectives'){this.setSpecificObjectivesTextInputs(newInputs)}  
        else if(inputsToSet === 'ResearchResults'){this.setResearchResultsTextInputs(newInputs)}
        else if(inputsToSet === 'Recommendations'){this.setRecommendationsTextInputs(newInputs)}
        else if(inputsToSet === 'Conclusions'){this.setConclusionsTextInputs(newInputs)}
        else if(inputsToSet === 'TheoreticalFramework'){this.setTheoreticalFrameworkTextInputs(newInputs)}
        else if(inputsToSet === 'LiteratureReview'){this.setLiteratureReviewTextInputs(newInputs)}
        else if(inputsToSet === 'Methodology'){this.setMethodologyTextInputs(newInputs)}
        else if(inputsToSet === 'DiscussionOfResults'){this.setDiscussionOfResultsTextInputs(newInputs)}

    }

postUserResearchSimilarity =  async () => 
    {

        // let referenceString = "Kamoga Henry   Payments Date  jacobe2016henry@gmail.com   23 / 07 / 2024  0771 977 854 / 0701 243 139   CLIENT PAYMENTS  CLIENT DETAILS  Name :   ATUHAISE ESTHER  Contact :   0760 187 099 / 0772 892 649  Address :   UNIK  Email :   estheratuhaise@gmail.com  Product Name   :   Real Time Internship Reporting Mobile   App  QUOTATION DETAILS  ITEM DESCRIPTION   COST FEE  Hosting Fee   50,000  Mobile App & System Design, Development and  Deployment, Chapter 4 - 6 Writing  300,000  Total :   350,000PAYMENTS DETAILS  DESCRIPTION   DATE   AMOUNT   BALANCE  Hosting   06 / 06 / 2024   50,000   0  Labour   06 / 06 / 2024   150,000   150,000  Labour   23 / 07 / 2024   100,000   50,000  Total :   300,000  ACCOUNT DETAILS  Type   Account number   In Names  Stanbic Bank   9030015591888   Kamoga henry  Airtel Money   0701 243 139   Kamoga henry  MTN Money   0771 977 854   Kamoga henry  Name :   Kamoga Henry   Signature";//this.state.pdfPlainText;
        let referenceString =this.state.pdfPlainText;
        let stringsToCompare = this.state.stringsToCompare;

        // console.log("Reference String"+referenceString)
        // console.log("Reference String LEN"+referenceString.length)

            const payload = {
                "referenceString": referenceString,
                "stringsToCompare": stringsToCompare,
            };
            // console.log("PAYLOAD"+JSON.stringify(stringsToCompare))
            console.log("STRINGS TO COMPARE LEN"+stringsToCompare.length)

            try
            {
                const postRequest = await axios.post(APIResearchSimilarity ,payload)
                
                let result = postRequest;
                // console.log(result.data)
                let stringArray = result.data;
                const integerArray = stringArray.map(value => Math.floor(parseFloat(value)));
                // check if no similarities in uploads 
                const allZeros = integerArray.reduce((acc, value) => acc && value === 0.00, true);
                if (allZeros)
                    {
                        console.log("All zeros");
                        this.setState({showSimilaritiesResults:false})
                        this.setState({NoSimilaritiesInResults:"No Similarities With Other Uploaded Research "})
                        this.setState({NoSimilaritiesUploadedResearch:'Your Research Has No Similarities With Uploaded Research'});
                    }
                else
                    {
                        console.log("Not zeros");
                        this.setState({ResearchSimilarities:result.data});
                        this.setState({showSimilaritiesResults:true});
                    }
                
            }
            catch (error)
                {
                    console.log("===>" + error)
                    this.setState({SimilaritiesErrorMsg:error.message});
                }
    }
    
postUserResearch =  async () => 
    {
        this.setState({showPostingAnimation:true});
        // const {user} = this.context;

        // console.log("=======> Positing ")
        let uploadDate = getTodaysDate();
        let fileName = this.state.pdfFileName;
        let pdfPlainText = this.state.pdfPlainText;
        let researcher = this.state.userId;
        let university = this.state.userUniversity;
        let fileSize = this.state.fileSize;
        let topic = this.state.ResearchTopic;
        let area = parseInt(this.state.ResearchArea);
        let status = String(this.state.ResearchStatusTextInput);
        let project = String(this.state.ResearchProjectTextInput);
        let abstractList = JSON.stringify(this.state.AbstractInputs);
        let hypothesisList = JSON.stringify(this.state.HypothesisInputs);
        let mainObjectivesList = JSON.stringify(this.state.MainObjectivesInputs);
        let specificObjectivesList = JSON.stringify(this.state.SpecificObjectivesInputs);
        let researchResultsList = JSON.stringify(this.state.ResearchResultsInputs);
        let recommendationsList = JSON.stringify(this.state.RecommendationsInputs);
        let conclusionsList = JSON.stringify(this.state.ConclusionsInputs);
        let theoreticalFrameworkList = JSON.stringify(this.state.TheoreticalFrameworkInputs);
        let literatureReviewList = JSON.stringify(this.state.LiteratureReviewInputs);
        let methodologyList = JSON.stringify(this.state.MethodologyInputs);
        let discussionOfResultsList = JSON.stringify(this.state.DiscussionOfResultsInputs);

        if ( fileName.length === 0 || status.length === 0 || area.length === 0 || project.length === 0)
            {this.setState({showRequiredAttributesAlert:true});}
        else
        {

            const formData = new FormData();
            formData.append('fileName',fileName);
            formData.append('fileSize',fileSize);
            formData.append('researcherName',researcher);
            formData.append('researchArea',area);
            formData.append('researchStatus',status);
            formData.append('researchType',project);
            formData.append('universityName',university);
            formData.append('pdfPlainText',pdfPlainText);
            formData.append('researchTopic',topic);
            formData.append('researchAbstract',abstractList);
            formData.append('researchSpecificObjectives',specificObjectivesList);
            formData.append('researchMainObjectives',mainObjectivesList);
            formData.append('researchHypothesis',hypothesisList);
            formData.append('researchTheoreticalFrame',theoreticalFrameworkList);
            formData.append('researchLiteratureReview',literatureReviewList);
            formData.append('researchMethodology',methodologyList);
            formData.append('researchResults',researchResultsList);
            formData.append('researchDiscussionOfResults',discussionOfResultsList);
            formData.append('researchRecommendations',recommendationsList);
            formData.append('researchConclusions',conclusionsList);
            formData.append('uploadDate',uploadDate);

            try
            {
                const postRequest = await axios.post(APIResearchPost,formData,{headers})
                
                let result = postRequest;
                console.log(result.data)

                this.setState({postSuccessMsg:result.data});
                this.setState({showPostSuccessAlert: true});
                this.setState({showContinueToResearchBtn:true});
                this.setState({showPostingAnimation:false});
                this.setState({showPostErrorAlert:false});
                this.setState({showSubmitBtn:false});
                // this.clearInputs();

                // setTimeout(()=>{ this.props.navigate("/researches");},5000);
            }
            catch (error)
                {
                    this.setState({postErrorMsg:error.message});
                    this.setState({showPostSuccessAlert: false}); 
                    this.setState({showPostErrorAlert:true});
                }
        }
    }
clearInputs = () =>{
    // clear all inputs
    this.setState({file:" "});
    this.setState({pdfPlainText:" "});
    this.setState({fileName:" "});
    this.setState({fileSize:" "});
    this.setState({topic:''});
    this.setState({area:" "});
    this.setState({status:" "});
    this.setState({project:" "});
    this.setState({abstractList:" "});
    this.setState({hypothesisList:" "});
    this.setState({mainObjectivesList:" "});
    this.setState({specificObjectivesList:" "});
    this.setState({researchResultsList:" "});
    this.setState({recommendationsList:" "});
    this.setState({conclusionsList:" "});
    this.setState({theoreticalFrameworkList:" "});
    this.setState({literatureReviewList:" "});
    this.setState({methodologyList:" "});
    this.setState({discussionOfResultsList:" "});
}
render() {
    const {AbstractInputs,HypothesisInputs,MainObjectivesInputs,SpecificObjectivesInputs,ResearchTopic,userLoggedIn,completedValue,showPostErrorAlert,showPostingAnimation,
            ResearchResultsInputs,RecommendationsInputs,ConclusionsInputs,TheoreticalFrameworkInputs,ResearchArea,ResearchStatusTextInput,showContinueToResearchBtn,
            LiteratureReviewInputs,MethodologyInputs,DiscussionOfResultsInputs,ResearchProjectTextInput,ResearchStatusInput,AreasData,showPostSuccessAlert,
        } =this.state;

return (
        <div className='body-container'>
            <NavigationMenu/>
            <div className='inner-body-container'>
                <div className="alert alert-success bg-success text-light border-0 alert-dismissible" role="alert">
                    <strong>Submit Your Research</strong>  Today !!!!
                    <div className="custom-alert-btn-actions">
                        <ButtonGroup className="mb-2">
                            <Link to={"/researches"} >
                                <Button className='black-bg-color' >
                                    Research Uploads
                                </Button>
                            </Link>
                        </ButtonGroup> 
                    </div>
                </div>
                <div className="row">
                    <div >
                    <div className='card'>
                    <Tabs defaultActiveKey="ResearchHit" id="justify-tab-example" className="mb-2" justify>
                        <Tab eventKey="ResearchHit" title="Research-Overview">
                            <Accordion defaultActiveKey="0">
                                <Accordion.Item eventKey="1">
                                    <Accordion.Header>Topic / Title </Accordion.Header>
                                    <Accordion.Body>
                                        <label className="primary-text"><b>Topic / Title Name *</b></label>
                                        <input type="text"  onChange={text=>this.setResearchTopic(text)} className="form-control form-inputs"  autoComplete="off" placeholder="Topic / Title Name"/>
                                    </Accordion.Body>
                                </Accordion.Item>

                                <Accordion.Item eventKey="2">
                                    <Accordion.Header>Abstract</Accordion.Header>
                                    <Accordion.Body>
                                        <label className="primary-text"><b>Abstract *</b></label>
                                        {AbstractInputs.map((item,index)=>(
                                            <div key={index}>
                                                <input type="text"  className="form-control form-inputs" placeholder="Copy & Paste Paragraph.."
                                                value={item.text}
                                                onChange={text => this.handleTextInputChange(text,index,this.state.AbstractInputs,'Abstract')}
                                                />
                                                <br/>
                                            </div>
                                        ))}
                                        <br/>
                                        <center>
                                            <button type="button" className='btn btn-primary' onClick={()=>this.handleAdd(this.state.AbstractInputs,'Abstract')}>
                                                Add New Input
                                            </button>
                                            &nbsp;&nbsp;&nbsp;
                                            <button type="button" className='btn btn-danger' onClick={()=>this.handleDelete(this.state.AbstractInputs,'Abstract')}>
                                                Remove Input
                                            </button>
                                        </center>
                                    </Accordion.Body>
                                </Accordion.Item>

                                <Accordion.Item eventKey="3">
                                    <Accordion.Header>Main Objectives</Accordion.Header>
                                    <Accordion.Body>
                                        <label className="primary-text"><b>Main Objectives *</b></label>
                                        {MainObjectivesInputs.map((item,index)=>(
                                            <div key={index}>
                                                <input type="text"  className="form-control form-inputs" placeholder="Copy & Paste Main Objectives.."
                                                value={item.text}
                                                onChange={text => this.handleTextInputChange(text,index,this.state.MainObjectivesInputs,'MainObjectives')}
                                                />
                                                <br/>
                                            </div>
                                        ))}
                                        <br/>
                                        <center>
                                            <button type="button" className='btn btn-primary' onClick={()=>this.handleAdd(this.state.MainObjectivesInputs,'MainObjectives')}>
                                                Add New Input
                                            </button>
                                            &nbsp;&nbsp;&nbsp;
                                            <button type="button" className='btn btn-danger' onClick={()=>this.handleDelete(this.state.MainObjectivesInputs,'MainObjectives')}>
                                                Remove Input
                                            </button>
                                        </center>
                                    </Accordion.Body>
                                </Accordion.Item>

                                <Accordion.Item eventKey="4">
                                    <Accordion.Header>Specific Objectives</Accordion.Header>
                                    <Accordion.Body>
                                        <label className="primary-text"><b>Specific Objectives *</b></label>
                                        {SpecificObjectivesInputs.map((item,index)=>(
                                            <div key={index}>
                                                <input type="text"  className="form-control form-inputs" placeholder="Copy & Paste Specific Objectives.."
                                                value={item.text}
                                                onChange={text => this.handleTextInputChange(text,index,this.state.SpecificObjectivesInputs,'SpecificObjectives')}
                                                />
                                                <br/>
                                            </div>
                                        ))}
                                        <br/>
                                        <center>
                                            <button type="button" className='btn btn-primary' onClick={()=>this.handleAdd(this.state.SpecificObjectivesInputs,'SpecificObjectives')}>
                                                Add New Input
                                            </button>
                                            &nbsp;&nbsp;&nbsp;
                                            <button type="button" className='btn btn-danger' onClick={()=>this.handleDelete(this.state.SpecificObjectivesInputs,'SpecificObjectives')}>
                                                Remove Input
                                            </button>
                                        </center>
                                    </Accordion.Body>
                                </Accordion.Item>

                                <Accordion.Item eventKey="5">
                                    <Accordion.Header>Hypothesis</Accordion.Header>
                                    <Accordion.Body>
                                        <label className="primary-text"><b>Hypothesis *</b></label>
                                        {HypothesisInputs.map((item,index)=>(
                                            <div key={index}>
                                                <input type="text"  className="form-control form-inputs" placeholder="Copy & Paste Hypothesis Paragraph.."
                                                value={item.text}
                                                onChange={text => this.handleTextInputChange(text,index,this.state.HypothesisInputs,'Hypothesis')}
                                                />
                                                <br/>
                                            </div>
                                        ))}
                                        <br/>
                                        <center>
                                            <button type="button" className='btn btn-primary' onClick={()=>this.handleAdd(this.state.HypothesisInputs,'Hypothesis')}>
                                                Add New Input
                                            </button>
                                            &nbsp;&nbsp;&nbsp;
                                            <button type="button" className='btn btn-danger' onClick={()=>this.handleDelete(this.state.HypothesisInputs,'Hypothesis')}>
                                                Remove Input
                                            </button>
                                        </center>
                                    </Accordion.Body>
                                </Accordion.Item>

                                <Accordion.Item eventKey="6">
                                    <Accordion.Header>Theoretical Framework</Accordion.Header>
                                    <Accordion.Body>
                                        <label className="primary-text"><b>Theoretical Framework *</b></label>
                                        {TheoreticalFrameworkInputs.map((item,index)=>(
                                            <div key={index}>
                                                <input type="text"  className="form-control form-inputs" placeholder="Copy & Paste Theoretical Framework Paragraph.."
                                                value={item.text}
                                                onChange={text => this.handleTextInputChange(text,index,this.state.TheoreticalFrameworkInputs,'TheoreticalFramework')}
                                                />
                                                <br/>
                                            </div>
                                        ))}
                                        <br/>
                                        <center>
                                            <button type="button" className='btn btn-primary' onClick={()=>this.handleAdd(this.state.TheoreticalFrameworkInputs,'TheoreticalFramework')}>
                                                Add New Input
                                            </button>
                                            &nbsp;&nbsp;&nbsp;
                                            <button type="button" className='btn btn-danger' onClick={()=>this.handleDelete(this.state.TheoreticalFrameworkInputs,'TheoreticalFramework')}>
                                                Remove Input
                                            </button>
                                        </center>
                                    </Accordion.Body>
                                </Accordion.Item>

                                <Accordion.Item eventKey="7">
                                    <Accordion.Header>Literature Review</Accordion.Header>
                                    <Accordion.Body>
                                        <label className="primary-text"><b>Literature Review *</b></label>
                                        {LiteratureReviewInputs.map((item,index)=>(
                                            <div key={index}>
                                                <input type="text"  className="form-control form-inputs" placeholder="Copy & Paste LiteratureReview Paragraph.."
                                                value={item.text}
                                                onChange={text => this.handleTextInputChange(text,index,this.state.LiteratureReviewInputs,'LiteratureReview')}
                                                />
                                                <br/>
                                            </div>
                                        ))}
                                        <br/>
                                        <center>
                                            <button type="button" className='btn btn-primary' onClick={()=>this.handleAdd(this.state.LiteratureReviewInputs,'LiteratureReview')}>
                                                Add New Input
                                            </button>
                                            &nbsp;&nbsp;&nbsp;
                                            <button type="button" className='btn btn-danger' onClick={()=>this.handleDelete(this.state.LiteratureReviewInputs,'LiteratureReview')}>
                                                Remove Input
                                            </button>
                                        </center>
                                    </Accordion.Body>
                                </Accordion.Item>

                                <Accordion.Item eventKey="8">
                                    <Accordion.Header>Methodology</Accordion.Header>
                                    <Accordion.Body>
                                        <label className="primary-text"><b>Methodology *</b></label>
                                        {MethodologyInputs.map((item,index)=>(
                                            <div key={index}>
                                                <input type="text"  className="form-control form-inputs" placeholder="Copy & Paste Methodology Paragraph.."
                                                value={item.text}
                                                onChange={text => this.handleTextInputChange(text,index,this.state.MethodologyInputs,'Methodology')}
                                                />
                                                <br/>
                                            </div>
                                        ))}
                                        <br/>
                                        <center>
                                            <button type="button" className='btn btn-primary' onClick={()=>this.handleAdd(this.state.MethodologyInputs,'Methodology')}>
                                                Add New Input
                                            </button>
                                            &nbsp;&nbsp;&nbsp;
                                            <button type="button" className='btn btn-danger' onClick={()=>this.handleDelete(this.state.MethodologyInputs,'Methodology')}>
                                                Remove Input
                                            </button>
                                        </center>
                                    </Accordion.Body>
                                </Accordion.Item>


                                <Accordion.Item eventKey="9">
                                    <Accordion.Header>Research Results / Findings</Accordion.Header>
                                    <Accordion.Body>
                                        <label className="primary-text"><b>Research Results / Findings *</b></label>
                                        {ResearchResultsInputs.map((item,index)=>(
                                            <div key={index}>
                                                <input type="text"  className="form-control form-inputs" placeholder="Copy & Paste Research Results / Findings Paragraph.."
                                                value={item.text}
                                                onChange={text => this.handleTextInputChange(text,index,this.state.ResearchResultsInputs,'ResearchResults')}
                                                />
                                                <br/>
                                            </div>
                                        ))}
                                        <br/>
                                        <center>
                                            <button type="button" className='btn btn-primary' onClick={()=>this.handleAdd(this.state.ResearchResultsInputs,'ResearchResults')}>
                                                Add New Input
                                            </button>
                                            &nbsp;&nbsp;&nbsp;
                                            <button type="button" className='btn btn-danger' onClick={()=>this.handleDelete(this.state.ResearchResultsInputs,'ResearchResults')}>
                                                Remove Input
                                            </button>
                                        </center>
                                    </Accordion.Body>
                                </Accordion.Item>


                                <Accordion.Item eventKey="10">
                                    <Accordion.Header>Discussion Of Results</Accordion.Header>
                                    <Accordion.Body>
                                        <label className="primary-text"><b>Discussion Of Results *</b></label>
                                        {DiscussionOfResultsInputs.map((item,index)=>(
                                            <div key={index}>
                                                <input type="text"  className="form-control form-inputs" placeholder="Copy & Paste DiscussionOfResults Paragraph.."
                                                value={item.text}
                                                onChange={text => this.handleTextInputChange(text,index,this.state.DiscussionOfResultsInputs,'DiscussionOfResults')}
                                                />
                                                <br/>
                                            </div>
                                        ))}
                                        <br/>
                                        <center>
                                            <button type="button" className='btn btn-primary' onClick={()=>this.handleAdd(this.state.DiscussionOfResultsInputs,'DiscussionOfResults')}>
                                                Add New Input
                                            </button>
                                            &nbsp;&nbsp;&nbsp;
                                            <button type="button" className='btn btn-danger' onClick={()=>this.handleDelete(this.state.DiscussionOfResultsInputs,'DiscussionOfResults')}>
                                                Remove Input
                                            </button>
                                        </center>
                                    </Accordion.Body>
                                </Accordion.Item>

                                <Accordion.Item eventKey="11">
                                        <Accordion.Header>Recommendations</Accordion.Header>
                                        <Accordion.Body>
                                            <label className="primary-text"><b>Recommendations *</b></label>
                                            {RecommendationsInputs.map((item,index)=>(
                                                <div key={index}>
                                                    <input type="text"  className="form-control form-inputs" placeholder="Copy & Paste Recommendations Paragraph.."
                                                    value={item.text}
                                                    onChange={text => this.handleTextInputChange(text,index,this.state.RecommendationsInputs,'Recommendations')}
                                                    />
                                                    <br/>
                                                </div>
                                            ))}
                                            <br/>
                                            <center>
                                                <button type="button" className='btn btn-primary' onClick={()=>this.handleAdd(this.state.RecommendationsInputs,'Recommendations')}>
                                                    Add New Input
                                                </button>
                                                &nbsp;&nbsp;&nbsp;
                                                <button type="button" className='btn btn-danger' onClick={()=>this.handleDelete(this.state.RecommendationsInputs,'Recommendations')}>
                                                    Remove Input
                                                </button>
                                            </center>
                                        </Accordion.Body>
                                    </Accordion.Item>

                                    <Accordion.Item eventKey="12">
                                        <Accordion.Header>Conclusions</Accordion.Header>
                                        <Accordion.Body>
                                            <label className="primary-text"><b>Conclusions *</b></label>
                                            {ConclusionsInputs.map((item,index)=>(
                                                <div key={index}>
                                                    <input type="text"  className="form-control form-inputs" placeholder="Copy & Paste Conclusions Paragraph.."
                                                    value={item.text}
                                                    onChange={text => this.handleTextInputChange(text,index,this.state.ConclusionsInputs,'Conclusions')}
                                                    />
                                                    <br/>
                                                </div>
                                            ))}
                                            <br/>
                                            <center>
                                                <button type="button" className='btn btn-primary' onClick={()=>this.handleAdd(this.state.ConclusionsInputs,'Conclusions')}>
                                                    Add New Input
                                                </button>
                                                &nbsp;&nbsp;&nbsp;
                                                <button type="button" className='btn btn-danger' onClick={()=>this.handleDelete(this.state.ConclusionsInputs,'Conclusions')}>
                                                    Remove Input
                                                </button>
                                            </center>
                                        </Accordion.Body>
                                    </Accordion.Item>
                                </Accordion>
                        </Tab>
                        
                        <Tab eventKey="Attachments" title="Research-Status">
                            {this.state.showResearchStatusTab ? (<>
                            <div className='tab-research-body'>
                                <section className="section">
                                        <div className="row">
                                            <div className="col-lg-6">
                                                <div className="card">
                                                    <div className="card-body">
                                                        <h5 className="card-title">Attachments </h5>
                                                            <label className="primary-text">Attach a research PDF file </label>
                                                            <input type="file"  accept="application/pdf" style={{width:"50%"}}  className="form-control form-inputs file-uploads-inputs" 
                                                                onChange={e=>this.handleFile(e)}
                                                            />
                                                            <p className='text-danger'>{this.state.pdfPlainTextError}</p>
                                                            <p className='text-primary'>{this.state.pdfPlainTextSuccess}</p>

                                                            <br></br>

                                                            <label className="primary-text">Research Theme</label>
                                                            <select style={{width:"50%"}} className="form-control form-inputs"
                                                                value={ResearchArea}
                                                                onChange={text => this.setResearchAreaTextInput(text)}
                                                            >
                                                                <option> -- Select -- </option>
                                                                {AreasData && AreasData.map((item, index) => (<option key={index} value={item.id}> {item.name}</option>))}
                                                            </select>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="col-lg-6">
                                                <div className="card">
                                                    <div className="card-body">
                                                        <h5 className="card-title">Research Status</h5>
                                                        <label className="primary-text">Status </label>
                                                        <select style={{width:"50%"}} className="form-control form-inputs"
                                                            value={ResearchStatusInput}
                                                            onChange={text => this.setResearchStatusTextInput(text)}
                                                        >
                                                            <option > --Select -- </option>
                                                            <option value="Published">Published</option>
                                                            <option value="Completed" >Completed</option>
                                                            <option value="Working Progress" >Working Progress </option>
                                                        </select>
                                                        <br></br>

                                                        <label className="primary-text">Research Project </label>
                                                        <select style={{width:"50%"}} className="form-control form-inputs"
                                                            value={ResearchProjectTextInput}
                                                            onChange={text => this.setResearchProjectTextInput(text)}
                                                        >
                                                            <option > --Select -- </option>
                                                            <option value="Thesis" >Thesis -Phd</option>
                                                            <option value="Journal" >Journal - Others</option>
                                                            <option value="Dissertation">Dissertation - Master</option>
                                                        </select>

                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                </section>
                            </div>
                            <br/>
                            </>):(<><p className='text-danger topic-required-text'><b>Topic / Research Title Is Required ***</b></p><LockedTab/></>)}
                        </Tab>
                        
                        <Tab eventKey="Summary-Preview" title="Summary Preview">
                            {this.state.showSummaryPreviewTab ? (<>
                                <div className=''>
                                    <div className="w3-panel w3-border w3-pale-blue w3-leftbar w3-rightbar w3-border-blue w3-round-large">
                                        <br/>
                                        <p className='research-preview-title primary-text'>Author</p>
                                        <p className='primary-text research-preview-paragraph'>{this.state.userName}</p>
                                        <p className='primary-text research-preview-paragraph'>Research Project :: {ResearchProjectTextInput}</p>
                                        <p className='primary-text research-preview-paragraph'>Research Status :: {ResearchStatusTextInput}</p>
                                        <p className='primary-text research-preview-paragraph'>Research Theme ::  { getIdCategory(ResearchArea,this.state.AreasData)}</p>

                                        <p className='research-preview-title primary-text'>Topic / Title</p>
                                        <p className='primary-text research-preview-paragraph'>{ResearchTopic}</p>
                                        <br/>
                                        <p className='research-preview-title primary-text'>Abstract</p>
                                        {AbstractInputs && AbstractInputs.map((item,index)=>(
                                            <div key={index}>
                                                <div >
                                                    <p className='primary-text research-preview-paragraph'>{item.data}</p>
                                                </div>
                                            </div>
                                        ))}
                                        <br/>
                                        <p className='research-preview-title primary-text'>Main Objectives</p>
                                        {MainObjectivesInputs && MainObjectivesInputs.map((item,index)=>(
                                            <div key={index}>
                                                <div >
                                                    <p className='primary-text research-preview-paragraph'>{item.data}</p>
                                                </div>
                                            </div>
                                        ))}
                                        <br/>
                                        
                                        <p className='research-preview-title primary-text'>Specific Objectives</p>
                                        {SpecificObjectivesInputs && SpecificObjectivesInputs.map((item,index)=>(
                                            <div key={index}>
                                                <div >
                                                    <p className='primary-text research-preview-paragraph'>{item.data}</p>
                                                </div>
                                            </div>
                                        ))}
                                        <br/>
                                        <p className='research-preview-title primary-text'>Hypothesis</p>
                                        {HypothesisInputs && HypothesisInputs.map((item,index)=>(
                                            <div key={index}>
                                                <div >
                                                    <p className='primary-text research-preview-paragraph'>{item.data}</p>
                                                </div>
                                            </div>
                                        ))}
                                        <br/>
                                        <p className='research-preview-title primary-text'>Theoretical Frame Work</p>
                                        {TheoreticalFrameworkInputs && TheoreticalFrameworkInputs.map((item,index)=>(
                                            <div key={index}>
                                                <div >
                                                    <p className='primary-text research-preview-paragraph'>{item.data}</p>
                                                </div>
                                                
                                            </div>
                                        ))}
                                        <br/>
                                        <p className='research-preview-title primary-text'>Literature Review</p>
                                        {LiteratureReviewInputs && LiteratureReviewInputs.map((item,index)=>(
                                            <div key={index}>
                                                <div >
                                                    <p className='primary-text research-preview-paragraph'>{item.data}</p>
                                                </div>
                                                
                                            </div>
                                        ))}
                                        <br/>
                                        <p className='research-preview-title primary-text'>Methodology</p>
                                        {MethodologyInputs && MethodologyInputs.map((item,index)=>(
                                            <div key={index}>
                                                <div >
                                                    <p className='primary-text research-preview-paragraph'>{item.data}</p>
                                                </div>
                                                
                                            </div>
                                        ))}
                                        <br/>
                                        <p className='research-preview-title primary-text'>Research Results</p>
                                        {ResearchResultsInputs && ResearchResultsInputs.map((item,index)=>(
                                            <div key={index}>
                                                <div >
                                                    <p className='primary-text research-preview-paragraph'>{item.data}</p>
                                                </div>
                                                
                                            </div>
                                        ))}
                                        <br/>
                                        <p className='research-preview-title primary-text'>Discussion Of Results</p>
                                        {DiscussionOfResultsInputs && DiscussionOfResultsInputs.map((item,index)=>(
                                            <div key={index}>
                                                <div >
                                                    <p className='primary-text research-preview-paragraph'>{item.data}</p>
                                                </div>
                                                
                                            </div>
                                        ))}
                                        <br/>
                                        <p className='research-preview-title primary-text'>Recommendations</p>
                                        {RecommendationsInputs && RecommendationsInputs.map((item,index)=>(
                                            <div key={index}>
                                                <div >
                                                    <p className='primary-text research-preview-paragraph'>{item.data}</p>
                                                </div>
                                                
                                            </div>
                                        ))}
                                        <br/>
                                        <p className='research-preview-title primary-text'>Conclusions</p>
                                        {ConclusionsInputs && ConclusionsInputs.map((item,index)=>(
                                            <div key={index}>
                                                <div >
                                                    <p className='primary-text research-preview-paragraph'>{item.data}</p>
                                                </div>
                                                
                                            </div>
                                        ))}
                                    </div>
                                </div>
                            </>):(<><LockedTab/></>)}
                        </Tab>
                        <Tab eventKey="Similarities" title="Similarities">
                            {this.state.showSimilaritiesTab ?(<>
                                <div className='tab-research-body'>
                                    <section className="section">
                                            <div className="row">
                                                <div className="col-lg-6">
                                                    <div className="card">
                                                        <div className="card-body">
                                                            <h5 className="card-title text-danger">Similarities With Uploaded Research </h5>
                                                            {this.state.showSimilaritiesResults?(<>
                                                            <p>{this.state.SimilaritiesErrorMsg}</p>
                                                            {this.state.ResearchSimilarities && this.state.ResearchSimilarities.map((item ,index)=>(
                                                                <div key={index} >
                                                                    <div className="alert border-danger alert-dismissible fade show" role="alert">
                                                                        <p className='text-danger' >Upload :  {index+1}</p>
                                                                        <CustomProgressBar percentage={item}/>
                                                                        <p></p>
                                                                    </div>
                                                                </div>
                                                            ))}
                                                            </>):(<><p className='text-danger'>{this.state.NoSimilaritiesInResults}</p></>)}
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="col-lg-6">
                                                    <div className="card">
                                                        <div className="card-body">
                                                            <h5 className="card-title text-primary">Research Details  </h5>
                                                            {this.state.showSimilaritiesResults?(<>
                                                                {this.state.ResearchesToCompareTo && this.state.ResearchesToCompareTo.map((item,index)=>(
                                                                    <div key={index} >
                                                                        <div className="alert border-primary alert-dismissible fade show" role="alert">
                                                                            <p className='text-primary'> Researcher : {index+1}</p>
                                                                            <p> {getIdFullName(item.researcherName,this.state.ResearchersData)} <span className="badge rounded-pill bg-success">{item.uploadDate}</span></p>
                                                                            <button type="button" onClick={()=>this.setResearcherPdfFile(item.pdfFile)} className="btn btn-primary similarities-view-btn">View Details</button>
                                                                        </div>
                                                                    </div>
                                                                ))} 
                                                            </>):(<><p className='text-primary'>{this.state.NoSimilaritiesInResults}</p></>)}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                    </section>
                                    <br></br>
                                    <section className="section">
                                            <div className="row">
                                                <div className="col-lg-6">
                                                    <div className="card">
                                                        <div className="card-body">
                                                            <h5 className="card-title text-danger">Your Uploads  </h5>
                                                            {this.state.showSimilaritiesResults?(<>
                                                                {this.state.pdfFilePreview && (
                                                                    <iframe
                                                                    src={this.state.pdfFilePreview}
                                                                    title="My Upload"
                                                                    width="100%"
                                                                    height="600px"
                                                                    style={{ border: '1px solid #ddd', marginTop: '20px' }}
                                                                    />
                                                                )}
                                                            </>):(<><p className='text-danger'>{this.state.NoSimilaritiesUploadedResearch}</p></>)}
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="col-lg-6">
                                                    <div className="card">
                                                        <div className="card-body">
                                                            <h5 className="card-title text-primary">Uploaded Research  </h5>
                                                            {this.state.showSimilaritiesResults?(<>
                                                                {this.state.ResearcherPdfFile ?(<Base64ToPdf base64PDF={this.state.ResearcherPdfFile} fileName="research.pdf"/>):(<><p className='text-primary'>No File Selected</p></>)}
                                                            </>):(<><p className='text-primary'>{this.state.NoSimilaritiesUploadedResearch}</p></>)}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                    </section>
                                </div>
                                <br/>
                            </>):(<><LockedTab/></>)}
                        </Tab>
                        <Tab eventKey="Submit" title="Submit">
                            {this.state.showSubmitTab ?(<>
                                <br/>
                                <div className='tab-research-body'>
                                    {userLoggedIn?(<>
                                        
                                        
                                            <section className="section">
                                                <div className="row">
                                                        <div className="col-lg-6">
                                                            <div className="card">
                                                                <div className="card-body">
                                                                <h5 className="card-title text-primary">Research Progress </h5>
                                                                <p className='text-primary'>Completed</p>
                                                                    <ProgressBar animated now={100} label={completedValue} />
                                                                    <br/><br/>
                                                                </div>
                                                            </div>
                                                        </div>

                                                        <div className="col-lg-6">
                                                            <div className="card">
                                                                <div className="card-body">
                                                                <h5 className="text-success card-title">Submit Your Research</h5>
                                                                <p className='text-success'>Submit Your Research</p>
                                                                {this.state.showSubmitBtn ? (<>
                                                                    <button type="button" className='btn btn-success'  onClick={()=>this.postUserResearch()}>
                                                                        Submit Research
                                                                    </button>  &nbsp;&nbsp;
                                                                </>):(<></>)}
                                                                    
                                                                    {showContinueToResearchBtn ?(<>
                                                                        <Link to={"/researches"} >
                                                                            <button type="button" className='btn btn-success'>
                                                                                Continue To Research Uploads
                                                                            </button>
                                                                        </Link>
                                                                    </>):(<></>)}
                                                                    <br/><br/>
                                                                    {this.state.showRequiredAttributesAlert ?(<> 
                                                                        <Alert variant="primary">
                                                                            <Alert.Heading>Required Attributes **</Alert.Heading>
                                                                                <p>1. Research PDF File </p>
                                                                                <p>2. Research Theme </p>
                                                                                <p>3. Research Status </p>
                                                                                <p>4. Research Project </p>
                                                                            <hr />
                                                                            <p className="mb-0">The Mentioned Above Are Required </p>
                                                                        </Alert><br></br>
                                                                    </>) :(<> </>)}
                                                                    {showPostingAnimation ? (<>
                                                                        <center>
                                                                        <div className="spinner-border text-danger" role="status">
                                                                            <span className="visually-hidden posting-spinner" >Loading...</span>
                                                                        </div>
                                                                        <p className='text-danger'><b>... Posting Your  Research ...</b></p>
                                                                    </center>
                                                                    </>):(<></>)}
                                                                    {showPostSuccessAlert ?(<> 
                                                                        <Alert variant="primary">
                                                                            <Alert.Heading>Response</Alert.Heading>
                                                                            <p>{this.state.postSuccessMsg}</p>
                                                                            <hr />
                                                                            <p className="mb-0">Thank You For Uploading Your Research !!</p>
                                                                        </Alert><br></br>
                                                                    </>) :(<> </>)}
                                                                    {showPostErrorAlert ?(<> 
                                                                        <Alert variant="danger">
                                                                            <Alert.Heading>Response</Alert.Heading>
                                                                            <p>{this.state.postErrorMsg}</p>
                                                                            <hr />
                                                                            <p className="mb-0">Try Again Uploading Your Research </p>
                                                                        </Alert><br></br>
                                                                    </>) :(<> </>)}
                                                                </div>
                                                            </div> 
                                                        </div>
                                                    </div>
                                            </section>
                                    </>):(<><GuestUser/><br/><br/> </>)}
                                </div>
                            </>):(<><LockedTab/></>)}
                        </Tab>
                    </Tabs>
                    </div>
                    </div>
                </div>
                </div>
        </div>

);
}}

function withNavigation(Component) {
    return function WrappedComponent(props) {
        const navigate = useNavigate();
        return <Component {...props} navigate={navigate} />;
    };
}

// Wrap the class component with the functional component to get access to navigate
export default withNavigation(Submit);