import React from 'react';
import VerticalHeader from './VerticalHeader';
import HorizontalHeader from './HorizontalHeader';

function NavigationMenu() {

    return (
        <>  
            <div id="horizontal-menu">
                <div className='nav-header-container'>
                    <HorizontalHeader/>
                </div>
            </div>
            <div id="vertical-menu">
                <VerticalHeader/>
            </div>
        </>
    );
}

export default NavigationMenu;