

import React,{Component} from 'react';
import ReactPaginate from "react-paginate";
import '../Utilities/css/main.css';


class PaginateTest extends Component {
    
    constructor(props){
        
        super(props);
        this.state = {
            data : [
                "A",
                "B",
                "C",
                "D",
                "E",
                "F",
                "G",
                "H",
                "I",
                "J",
                "K",
                "L",
                "M",
                "N",
                "O",
                "P",
                "Q",
                "R",
                "S",
                "T",
                "U",
                "V",
                "W",
                "X",
                "Y",
                "Z"
                ],
                recordsPerPage: 3,
                pageNumber:0,
                filterData:[],
            
            
    
        }    
    }
    
    componentDidMount() {
        this.setFilterData();
    }

setFilterData = () =>{
    this.setState({filterData:
                    this.state.data.filter((item, index) => {return (index >= this.state.pageNumber * this.state.recordsPerPage) & (index < (this.state.pageNumber + 1) * this.state.recordsPerPage);})
        })
        console.log("filterData"+JSON.stringify(this.state.filterData))
}
setPage = (e)=>{
    console.log("pageNumber"+this.state.pageNumber)
    console.log(e)
    this.setState({pageNumber:e}); 
    this.setFilterData();
    console.log("pageNumber"+this.state.pageNumber)

}

render (){
    return(
        <div>
            <div className="pagination-app">
                <br></br>
                    <ul className='text-white' >
                        {this.state.filterData && this.state.filterData.map((item, index) => <li key={index} > Item #{item}</li>)}
                    </ul>

                    <ReactPaginate 
                        previousLabel={"Previous"}
                        nextLabel={"Next"}
                        pageCount={Math.ceil(this.state.data.length / this.state.recordsPerPage)}
                        onPageChange={(event) => this.setPage(event.selected)}

                        containerClassName={"paginationButtons"}
                        previousClassName={"previousButton"}
                        nextLinkClassName={"nextButton"}
                        disabledClassName={"paginationDisabled"}
                        activeClassName={"paginationActive"}
                    />
            </div>
        </div>
    )
}


}
export default PaginateTest;
