import React, { useState } from 'react';
import { Button } from 'react-bootstrap';

import { APIResearchPdfFile } from "../Utilities/APIS";

const PdfViewer = ({ pdfFile }) => {
    const [reloadKey, setReloadKey] = useState(0);

    const handleFileReload = () => {
        setReloadKey(reloadKey + 1); // Update the key to trigger re-render
    };

    // console.log("===" + APIResearchPdfFile + pdfFile);

    return (
        <div>
            <br />
            <Button variant="primary" onClick={handleFileReload}>Reload File</Button>&nbsp;&nbsp;
            <br />
            <div style={{ height: '500px' }}>
                <iframe
                    key={reloadKey} // Assign a key to re-render on change
                    src={APIResearchPdfFile + pdfFile}
                    width="100%"
                    height="100%"
                    title="Research Uploaded"
                />
            </div>
        </div>
    );
};

export default PdfViewer;

