import React from 'react';

const CustomProgressBar = ({ percentage }) => {
// Ensure the percentage is within the valid range of 0 to 100
const integer = Math.floor(percentage);
// console.log("integer Value"+ integer)
const progress = Math.max(0, Math.min(integer, 100));

return (
    <div style={styles.container}>
        <div style={{ ...styles.filler, width: `${progress}%` }}>
        <span style={styles.label}>{`${percentage} %`}</span>
        </div>
    </div>
);
};


// Styles for the progress bar
const styles = {
    container: {
        height: '18px',
        width: '100%',
        backgroundColor: '#e0e0de',
        borderRadius: '10px',
        overflow: 'hidden',
    },
    filler: {
        height: '100%',
        backgroundColor: 'red',
        textAlign: 'right',
        transition: 'width 0.5s ease-in-out',
        borderRadius: 'inherit',
    },
    label: {
        padding: '5px',
        color: 'white',
        fontWeight: 'bold',
    },
};

export default CustomProgressBar;
