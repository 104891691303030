

import React,{Component} from 'react';
import axios from 'axios';

// redux
import {useNavigate } from 'react-router-dom';
import Dropdown from 'react-bootstrap/Dropdown';
import ButtonGroup from 'react-bootstrap/ButtonGroup';
import Button from 'react-bootstrap/Button';
import '../../Utilities/css/main.css';
import '../../Utilities/assets/bootstrap/css/bootstrap.min.css';
import '../../Utilities/assets/css/style.css';

import NavigationMenu from '../Navigation/NavigationMenu';
import { APIProjectsList, APIResearchersList, AUTH_TOKEN } from '../../Utilities/APIS';
import { daysPassed, getIdCategory } from '../../Utilities/Functions';
import NextIcon from "../../Utilities/imgs/next.png"

const headers = {'Authorization': `Bearer ${AUTH_TOKEN}`};

class Projects extends Component {
    
constructor(props){
    
    super(props);
    this.state = {
        ProjectsData:[],
        FilteredData:[],
        UniversitiesData:[],
    }    
}

componentDidMount() {
    this.loadData();
    this.initializeReduxData();
}

loadData = () =>{

    axios.get(APIProjectsList,{headers})
    .then(res => {
            let results =JSON.stringify(res.data);
            let jsonResults =JSON.parse(results); 
            this.setState({FilteredData:jsonResults})
            this.setState({ProjectsData:jsonResults})
        })
    .catch(err=>{console.log(err);})

    axios.get(APIResearchersList,{headers})
    .then(res => {
            let results =JSON.stringify(res.data);
            let jsonResults =JSON.parse(results); 
            this.setState({ResearchersData:jsonResults})
        })
    .catch(err=>{console.log(err);})
}

initializeReduxData = () =>{
    const storedState = localStorage.getItem('reduxState');
    if (storedState) {
        const parsedState = JSON.parse(storedState);
        this.setState({ UniversitiesData: parsedState.sliceUniversities.UniversitiesData});
    }
};

showAllRecordsData = ()=>{this.setState({FilteredData:this.state.ProjectsData})}
filterDataByUniversity = (id) => {
    let code = String(id)
    let filteredArray = this.state.ProjectsData.filter(item => item.universityName === code);
    this.setState({FilteredData:filteredArray})
};

goToDetailsScreen = (
    researcherName,uploadDate,projectEnd,projectStart,universityName,
    projectTitle,projectFunders,projectDescription,projectMembers
) =>{

let detailsProps = {
    projectTitle:projectTitle,
    researcherName:researcherName,
    uploadDate:uploadDate,
    projectEnd:projectEnd,
    projectStart:projectStart,
    universityName:universityName,
    projectDescription:projectDescription,

    projectFunders:JSON.parse(projectFunders),
    projectMembers:JSON.parse(projectMembers),
    
};
this.props.navigate("/projectDetails",{ state: detailsProps });
}


displayProjects = () =>{
    return(
        this.state.FilteredData.map((item,index)=>(
            <div key={index}>
                <div className="alert alert-primary alert-dismissible fade show" role="alert">
                    <h4 className="alert-heading">{item.projectTitle} |  {getIdCategory (item.universityName,this.state.UniversitiesData)} University</h4>
                    <p>{item.projectDescription}</p>
                    <div  className="card-days-div">
                        <span className='log-green-text'>{daysPassed (item.uploadDate)} days ago</span>
                    </div>
                    <hr/>
                    <p className="mb-0">&nbsp;&nbsp;&nbsp; Start Period <b>{item.projectStart}</b> &nbsp;&nbsp;&nbsp; End Period <b>{item.projectEnd}</b></p>
                    {/* <button type="button" className="btn-close" data-bs-dismiss="alert" aria-label="Close">Details</button> */}
                    <div  className="card-details-div">
                        <table>
                            <tbody>
                            <tr>
                                <td>
                                    <p className="log-green-text details-label">Details</p>
                                </td>
                                <td>
                                    <img  src={NextIcon} alt='action btn img' className='card-next-icon'
                                        onClick={()=>this.goToDetailsScreen(
                                            item.researcherName,item.uploadDate,item.projectEnd,item.projectStart,
                                            item.universityName,item.projectTitle,item.projectFunders,item.projectDescription,
                                            item.projectMembers
                                        )}/>
                                </td>
                            </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        ))
    )
}

render() {
    const {UniversitiesData} =this.state;

return (
            <div className='body-container'>
                <NavigationMenu/>
                <div className='inner-body-container'>
                    <div className="alert alert-success bg-success text-light border-0 alert-dismissible" role="alert">
                        <strong className='strong-text white-text' >Projects </strong><span className='strong-text white-text'>View</span>
                        <div className="custom-alert-btn-actions">
                            <ButtonGroup className="mb-2">
                                    <Button className='black-bg-color' >
                                        Submit Project
                                    </Button>
                            </ButtonGroup> 
                        </div>
                    </div>
                    <div className="row">
                        <div className='drop-down-researches-div'>
                            <Button onClick={()=>this.showAllRecordsData()} variant="dark">Show All</Button>
                            <Dropdown className="d-inline mx-2">
                                <Dropdown.Toggle variant="warning" id="dropdown-basic">
                                    Select Your University
                                </Dropdown.Toggle>

                                <Dropdown.Menu>
                                    {UniversitiesData && UniversitiesData.map((item, index)=>(
                                        <Dropdown.Item key={index} onClick={()=>this.filterDataByUniversity(item.id)}>
                                            <label  > {item.name}</label>
                                        </Dropdown.Item>
                                    ))}
                                </Dropdown.Menu>
                            </Dropdown>
                            </div>
                            <div>
                                {this.displayProjects()}
                            </div>
                    </div>
                </div>
            </div>

);
}}

function withNavigation(Component) {
    return function WrappedComponent(props) {
        const navigate = useNavigate();
        return <Component {...props} navigate={navigate} />;
    };
}


// Wrap the class component with the functional component to get access to navigate
export default withNavigation(Projects);
