import { FETCH_RESEARCHES_DATA_FAILURE, FETCH_RESEARCHES_DATA_REQUEST, FETCH_RESEARCHES_DATA_SUCCESS } from "../Actions/actions";



const initialState = {ResearchesData:[], error:null}

const researchesReducer = (state=initialState, action) =>{
    switch(action.type){
        case FETCH_RESEARCHES_DATA_REQUEST:
            return {...state,error:null}
        case FETCH_RESEARCHES_DATA_SUCCESS:
            return{...state,ResearchesData:action.payload,error:null}
        case FETCH_RESEARCHES_DATA_FAILURE:
            return {...state,error:action.error}
        default: return state;
    }
};

export default researchesReducer;