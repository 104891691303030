

import React,{Component} from 'react';
import { useLocation, useNavigate, useParams,Link } from 'react-router-dom';
import '../Utilities/css/main.css';
import '../Utilities/assets/bootstrap/css/bootstrap.min.css';
import '../Utilities/assets/css/style.css';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import NavigationMenu from './Navigation/NavigationMenu';
// const headers = {'Authorization': `Bearer ${AUTH_TOKEN}`};

class Themes extends Component {
    
constructor(props){
    
    super(props);
    this.state = {
        ResearchAreasData:[],

    }    
}

componentDidMount(){
    this.initializeReduxData();
}

initializeReduxData = () =>{
    const storedState = localStorage.getItem('reduxState');
    if (storedState) {
        const parsedState = JSON.parse(storedState);
        this.setState({ ResearchAreasData: parsedState.sliceResearchAreas.ResearchAreasData});
        console.log(JSON.stringify(parsedState.sliceResearchAreas.ResearchAreasData))
    }
};

displayNestedData = (Data) => {
    return(JSON.parse(Data).map((item, itemIndex) => (
        <div key={itemIndex}>
            <p className='text-black'>{item.data} </p>
        </div>
    )))
} 

displayRecordsData = () =>{
    return(
            this.state.ResearchAreasData.map((item,index)=>(
            <div key={index}>
                <div className="card research-display-card">
                    <div className="card-header">
                        <p className="log-green-text">{item.name}</p>
                    </div>
                    <div className="card-body">
                        {this.displayNestedData(item.description)}
                    </div>
                    {/* <div className="card-footer">
                    <p className='text-black' > Research Status  | <b>{item.researchStatus}</b> &nbsp;&nbsp;&nbsp;&nbsp; Research Area  | <b>{getIdCategory(item.researchArea,this.state.AreasData)}</b> &nbsp;&nbsp;&nbsp;&nbsp; Research Type | <b>{item.researchType}</b> &nbsp;&nbsp;&nbsp;&nbsp; Uploaded Date | <b>{item.uploadDate}</b></p>
                    </div> */}
                </div>
            </div>
        ))
    )
}
render() {

return (
            <div className='body-container'>
                <NavigationMenu/>
                <div className='inner-body-container'>
                    <div className="alert alert-success bg-success text-light border-0 alert-dismissible" role="alert">
                        <strong className='strong-text white-text' >Research Themes  </strong>
                        <span className='strong-text white-text'>List</span>
                    </div>

                    <div className="col-12">
                        
                        <div className="card">
                            <div className="card-body">
                                {this.displayRecordsData()}
                            </div>
                        </div>
                    </div>
                </div>
        </div>
);
}}

// This is a higher-order component to pass hooks to class components
function withRouter(Component) {
    function ComponentWithRouterProp(props) {
        let location = useLocation();
        let navigate = useNavigate();
        let params = useParams();
        return (
        <Component
            {...props}
            router={{ location, navigate, params }}
        />
        );
    }

    return ComponentWithRouterProp;
}
export default withRouter(Themes);