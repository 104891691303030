

export const convertToUpperCase = (Name) =>{
    return Name.toUpperCase();
}

export const clearLocalStorageData = () =>{
    localStorage.removeItem('UserDetails');
    localStorage.removeItem('UserResearch');
    localStorage.removeItem('reduxState');
}
export const getIdFullName=(ID,Data)=>{
    let Name = ' ';
    // console.log("DATA"+JSON.stringify(Data))
    // console.log("ID"+ ID);
    let Id = parseInt(ID) ;
    Data.map((item,index)=>{if (item.id === Id){Name = item.fullName;}})
    return Name.toUpperCase();
}


export const getIdCategory=(ID,Data)=>{
    let Name = ' ';
    // console.log("ID==>"+ID)
    // console.log("Contr"+JSON.stringify(Data))
    let Id =  parseInt(ID) ;
    Data.map((item,index)=>{if (item.id === Id){Name = item.name;}})
    return Name.toUpperCase();
}


export const getTodaysDate = ()=>{
    const today = new Date();
    const year = today.getFullYear();
    const month = String(today.getMonth() + 1).padStart(2, '0'); // Months are zero-indexed
    const day = String(today.getDate()).padStart(2, '0');

    const formattedDate = `${month}/${day}/${year}`;
    // console.log(formattedDate); // Outputs: MM/DD/YYYY
    return formattedDate;
};


export const getCurrentUserId = () =>{
    const user = localStorage.getItem('UserDetails');
    let userId = " ";
        if (user) {
            const jsonData = JSON.parse(user)
            userId = jsonData.id;
        }
    console.log("userId"+userId);
    return parseInt(userId);
}

export const daysPassed = (givenDate) => {
    // Get the current date
    const currentDate = new Date();

    // Parse the given date (MM/DD/YYYY format)
    const startDate = new Date(givenDate);

    // Check if the given date is valid
    if (isNaN(startDate.getTime())) {
        console.error('Invalid date format. Please provide a valid date.');
        return;
    }

    // Calculate the difference in milliseconds
    const differenceInTime = currentDate - startDate;

    // Convert milliseconds to days
    const differenceInDays = Math.floor(differenceInTime / (1000 * 60 * 60 * 24));

    return differenceInDays;
}