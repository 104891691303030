import { FETCH_RESEARCH_AREAS_DATA_FAILURE, FETCH_RESEARCH_AREAS_DATA_REQUEST, FETCH_RESEARCH_AREAS_DATA_SUCCESS } from "../Actions/actions";

const initialState = {ResearchAreasData:[],error:null}

const researchAreasReducer = ( state=initialState, action)=>{
    switch(action.type){
        case FETCH_RESEARCH_AREAS_DATA_REQUEST:
            return {...state,error:null}
        case FETCH_RESEARCH_AREAS_DATA_SUCCESS:
            return{...state, ResearchAreasData:action.payload}
        case FETCH_RESEARCH_AREAS_DATA_FAILURE:
            return{...state,error:action.payload}
        default : return state;
    }
};


export default researchAreasReducer;