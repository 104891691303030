


import React,{Component} from 'react';
import axios from 'axios';
// redux 
import { connect } from 'react-redux';
import { Link, useNavigate} from 'react-router-dom';
import '../Utilities/css/main.css';
import '../Utilities/assets/bootstrap/css/bootstrap.min.css';
import '../Utilities/assets/vendor/bootstrap-icons/bootstrap-icons.css';
import '../Utilities/assets/css/style.css';
import ButtonGroup from 'react-bootstrap/ButtonGroup';
import Button from 'react-bootstrap/Button';
import { APIAreasGet, APIResearcherGetById, APIResearchersList, APIResearchViewsByName, AUTH_TOKEN } from '../Utilities/APIS';
import { convertToUpperCase, daysPassed, getIdCategory, getIdFullName} from '../Utilities/Functions';
import NextIcon from "../Utilities/imgs/next.png"
import { fetchResearchAreasData, fetchUniversitiesData } from '../Actions/actions';
import GuestUser from './GuestUser';
import NavigationMenu from './Navigation/NavigationMenu';

const headers = {'Authorization': `Bearer ${AUTH_TOKEN}`};

class Dashboard extends Component {
    
constructor(props){
    
    super(props);
    this.state = {
        userLoggedIn:false,
        AreasData:[],
        FilteredData:[],
        CompletedData:[],
        PublishedData:[],
        WorkingPublishedData:[],
        UserResearch:[],
        ResearchersData:[],
        ResearchViews:[],
        userName:'',
        userId:'',


        // Loading 
        ShowAxiosErrorAlert:false,
        AxiosErrorMessage:'',
        AxiosErrorResponse:'',
        AllInputsRequiredError:'',


    }    
}

componentDidMount(){ 

    this.loadUser();
    
    axios.get(APIAreasGet,{headers})
    .then(res => {
        let results =JSON.stringify(res.data);
        let jsonResults =JSON.parse(results); 
        this.setState({AreasData:jsonResults})
        })
    .catch(error=>{console.log(error);
        this.setState({AxiosErrorMessage:error.message});
        this.setState({AxiosErrorResponse:error.request.response});
        this.setState({ShowAxiosErrorAlert:true});
    })
    setTimeout(()=>{ this.loadData ()},1000);
    // setTimeout(()=>{this.storeDashboardUserData()},5000)

}

loadUser = ()=>{
    const user = localStorage.getItem('UserDetails');
    if (user) {
        const jsonData = JSON.parse(user)
        let userName = jsonData.name;
        let userId = jsonData.id;
        let loggedIn = jsonData.loggedIn;

        this.setState({userName:userName});
        this.setState({userLoggedIn:loggedIn});
        this.setState({userId:userId});

    }

    this.props.fetchResearchAreasData();
    this.props.fetchUniversitiesData();

    axios.get(APIResearchersList,{headers})
    .then(res => {
            let results =JSON.stringify(res.data);
            let jsonResults =JSON.parse(results); 
            this.setState({ResearchersData:jsonResults})
        })
    .catch(error=>{console.log(error);
        this.setState({AxiosErrorMessage:error.message});
        this.setState({AxiosErrorResponse:error.request.response});
        this.setState({ShowAxiosErrorAlert:true});
    })
}
    
        
loadData = () =>{

    // console.log("===>"+APIResearcherGetById+this.state.userId)
    axios.get(APIResearcherGetById+this.state.userId,{headers})
    .then(res => {
        let results =JSON.stringify(res.data);
        let UserResearch =JSON.parse(results); 

        // filter Progress 
        let progress = "Published";
        let completed = "Completed";
        let workingProgress = "Working Progress";

        let filteredPublishedData = UserResearch.filter(item => item.researchStatus === progress);
        let filteredCompletedData = UserResearch.filter(item => item.researchStatus === completed);
        let filteredWorkingPublishedData = UserResearch.filter(item => item.researchStatus === workingProgress);
        this.setState({PublishedData:filteredPublishedData})
        this.setState({CompletedData:filteredCompletedData})
        this.setState({WorkingPublishedData:filteredWorkingPublishedData})
        })
    .catch(error=>{console.log(error);
        this.setState({AxiosErrorMessage:error.message});
        this.setState({AxiosErrorResponse:error.request.response});
        this.setState({ShowAxiosErrorAlert:true});
    })

    axios.get(APIResearchViewsByName+this.state.userId,{headers})
    .then(res => {
            let results =JSON.stringify(res.data);
            let jsonResults =JSON.parse(results); 
            let filteredData = jsonResults.filter(item => item.researchViewer !== this.state.userId);
            this.setState({ResearchViews:filteredData})
        })
    .catch(error=>{
        this.setState({AxiosErrorMessage:error.message});
        this.setState({AxiosErrorResponse:error.request.response});
        this.setState({ShowAxiosErrorAlert:true});
    })
}

goToDetailsScreen = (
    Topic,Name,Area,Status,pdfFile,

    researchAbstract,
    researchMainObjectives,
    researchSpecificObjectives,
    researchHypothesis,
    researchTheoreticalFrame,
    researchLiteratureReview,
    researchMethodology,
    researchResults,
    researchDiscussionOfResults,
    researchRecommendations,
    researchConclusions,
) =>{

let detailsProps = {
    researchTopic:Topic,
    researcherName:Name,
    researchArea:Area,
    researchStatus:Status,
    pdfFile:pdfFile,


    researchAbstract:JSON.parse(researchAbstract),
    researchMainObjectives:JSON.parse(researchMainObjectives),
    researchSpecificObjectives:JSON.parse(researchSpecificObjectives),
    researchHypothesis:JSON.parse(researchHypothesis),
    researchTheoreticalFrame:JSON.parse(researchTheoreticalFrame),
    researchLiteratureReview:JSON.parse(researchLiteratureReview),
    researchMethodology:JSON.parse(researchMethodology),
    researchResults:JSON.parse(researchResults),
    researchDiscussionOfResults:JSON.parse(researchDiscussionOfResults),
    researchRecommendations:JSON.parse(researchRecommendations),
    researchConclusions:JSON.parse(researchConclusions),
};
this.props.navigate("/details",{ state: detailsProps });
}

goToViewerDetailsScreen = (researchViewer,pdfFile,viewDate) =>{
    let viewerProps = {
        researchViewer:researchViewer,
        pdfFile:pdfFile,
        viewDate:viewDate
    };

    this.props.navigate("/viewer",{ state: viewerProps });
}
goToViewsViewMore = () =>{
    // console.log("this.state.ResearchViews"+JSON.stringify(this.state.ResearchViews))
    let viewerProps = {
        ViewData:this.state.ResearchViews
    };

    this.props.navigate("/viewsViewMore",{ state: viewerProps });
}
goToViewMore = (Data,researchStatus) =>{
    // console.log("this.state.ResearchViews"+JSON.stringify(this.state.ResearchViews))
    let viewerProps = {
        ViewData:Data,
        userName:this.state.userName,
        researchStatus:researchStatus
    };

    this.props.navigate("/viewMore",{ state: viewerProps });
}
render() {
    const {WorkingPublishedData,PublishedData,CompletedData,userLoggedIn,ResearchViews} =this.state;

return (
        <div className='body-container'>
            <NavigationMenu/>
            <div className='inner-body-container'>
            {userLoggedIn ?(<>
                    <div className="alert alert-success bg-success text-light border-0 alert-dismissible" role="alert">
                        <strong>Dashboard</strong> 
                        <div className="custom-alert-btn-actions">
                            <ButtonGroup className="mb-2">
                                <Link to={"/submit"} >
                                    <Button className='black-bg-color' >
                                        Submit Research
                                    </Button>
                                </Link>
                                <Link to={"/researches"} >
                                    <Button className='black-bg-color' >
                                        Research Uploads
                                    </Button>
                                </Link>
                            </ButtonGroup> 
                            </div>
                    </div>
                    {this.state.ShowAxiosErrorAlert ?(<>
                        <div className="alert alert-danger bg-danger text-light border-0 alert-dismissible fade show" role="alert">
                            <p>{this.state.AllInputsRequiredError}</p>
                            <p>{this.state.AxiosErrorMessage}</p>
                            <p>{this.state.AxiosErrorResponse}</p>
                        </div>
                    </>):(<></>)}
                    <br/>


                    <div className="row">
                    <div className="col-xl-3 col-md-6 mb-4">
                            <div className="card border-left-warning shadow h-100 py-2">
                            <span onClick={()=>this.goToViewMore(this.state.CompletedData, "Completed")} >
                                <div className="card-body">
                                    <div className="row no-gutters align-items-center">
                                        <div className="col mr-2">
                                            <div className="text-xs font-weight-bold text-warning text-uppercase mb-1">Completed</div>
                                            <div className="h5 mb-0 font-weight-bold text-warning text-gray-800">{CompletedData.length}</div>
                                        </div>
                                        <div className="col-auto">
                                            <i className="bi bi-brush-fill text-warning"></i>
                                        </div> 
                                    </div>
                                </div>
                            </span>
                            </div>
                        </div>

                        <div className="col-xl-3 col-md-6 mb-4">
                            <div className="card border-left-success shadow h-100 py-2">
                                <span onClick={()=>this.goToViewMore(this.state.PublishedData, "Published")} >
                                <div className="card-body">
                                    <div className="row no-gutters align-items-center">
                                        <div className="col mr-2">
                                            <div className="text-xs font-weight-bold text-success text-uppercase mb-1">
                                            Published</div>
                                            <div className="h6 mb-0 font-weight-bold text-success text-gray-800">{PublishedData.length}</div>
                                        </div>
                                        <div className="col-auto">
                                            <i className="bi bi-brush-fill text-success"></i>
                                        </div> 
                                    </div>
                                </div>
                                </span>
                            </div>
                        </div>
                        <div className="col-xl-3 col-md-6 mb-4 ">
                            <div className="card border-left-primary shadow h-100 py-2">
                            <span onClick={()=>this.goToViewMore(this.state.WorkingPublishedData, "Working Progress")}  >
                                <div className="card-body">
                                    <div className="row no-gutters align-items-center">
                                        <div className="col mr-2">
                                            <div className="text-xs font-weight-bold text-primary text-uppercase mb-1">
                                            Working Progress</div>
                                            <div className="h6 mb-0 font-weight-bold text-primary">{WorkingPublishedData.length}</div>
                                        </div>
                                        <div className="col-auto">
                                            <i className="bi bi-brush-fill text-primary"></i>
                                        </div> 
                                    </div>
                                </div>
                            </span>
                            </div>
                        </div>

                        <div className="col-xl-3 col-md-6 mb-4">
                            <div className="card border-left-info shadow h-100 py-2">
                            <span onClick={()=>this.goToViewsViewMore()}  >
                                <div className="card-body">
                                    <div className="row no-gutters align-items-center">
                                        <div className="col mr-2">
                                            <div className="text-xs font-weight-bold text-info text-uppercase mb-1">Views</div>
                                            <div className="h6 mb-0 mr-3 font-weight-bold  text-info  text-gray-800">{ResearchViews.length}</div>
                                        </div>
                                        <div className="col-auto">
                                            <i className="bi bi-eye-fill text-info"></i>
                                        </div> 
                                    </div>
                                </div>
                            </span>
                            </div>
                        </div>
                </div>

                <div className="row">
                    <div className="col-12">
                        <div className="card recent-sales overflow-auto">
                            <div className="card-body main-card">
                            <h5 className="card-title text-primary text-warning">Research Views </h5>
                            <div className='dashboard-view-more' >
                                <button className='btn btn-outline-dark' onClick={()=>this.goToViewsViewMore()} >
                                    <span className='text-warning'>View More</span> &nbsp;&nbsp;
                                    <i className="bi bi-arrow-right-square-fill text-warning view-more-bi"></i>
                                </button>
                            </div>
                            <div className='breaking-space-div'>
                                {ResearchViews && ResearchViews.slice(0,5).map((item, index)=>(
                                    <div key={index}>
                                        <div className="card research-display-card">
                                            <div className="card-header">
                                                <p className="log-green-text">{getIdFullName(item.researchViewer, this.state.ResearchersData)}  </p>
                                                <div  className="card-details-div">
                                                    <table>
                                                        <tbody>
                                                            <tr>
                                                                <td>
                                                                    <p className="log-green-text details-label">Details</p>
                                                                </td>
                                                                <td>
                                                                    <img  src={NextIcon} alt='action btn img' className='card-next-icon'
                                                                        onClick={()=>this.goToViewerDetailsScreen(item.researchViewer,item.fileName,item.viewDate)}/>
                                                                </td>
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div>
                                            <div className="card-body">
                                                <p className='text-black'>{item.researchTopic} </p>
                                                <div  className="card-days-div">
                                                <span className='log-green-text'>{daysPassed(item.viewDate)} days ago</span>
                                                </div>
                                                <p className='text-black' >Status {item.researchStatus} &nbsp;&nbsp;&nbsp;&nbsp;Research Area  | <b>{getIdCategory(item.researchArea,this.state.AreasData)}</b>&nbsp;&nbsp;&nbsp;&nbsp; Viewed Date | <b>{item.viewDate}</b> &nbsp;&nbsp;&nbsp;&nbsp;Uploaded Date | <b>{item.uploadDate}</b></p>
                                            </div>
                                        </div>
                                    </div>
                                ))}
                            </div>

                            </div>

                        </div>
                    </div>

                    <div className="col-12">
                        <div className="card recent-sales overflow-auto">
                            <div className="card-body main-card">
                            <h5 className="card-title text-primary text-warning">Completed Uploads </h5>
                            <div className='dashboard-view-more' >
                                <button className='btn btn-outline-dark' onClick={()=>this.goToViewMore(this.state.CompletedData, "Completed")} >
                                    <span className='text-warning'>View More</span> &nbsp;&nbsp;
                                    <i className="bi bi-arrow-right-square-fill text-warning view-more-bi"></i>
                                </button>
                                
                            </div>
                            <div className='breaking-space-div'>
                                {CompletedData && CompletedData.slice(0,5).map((item, index)=>(
                                    <div key={index}>
                                        <div className="card research-display-card">
                                            <div className="card-header">
                                                <p className="log-green-text">{convertToUpperCase(this.state.userName)}</p>
                                                    <div  className="card-details-div">
                                                        <table>
                                                            <tbody>
                                                            <tr>
                                                                <td>
                                                                    <p className="log-green-text details-label">Details</p>
                                                                </td>
                                                                <td>
                                                                    <img  src={NextIcon} alt='action btn img' className='card-next-icon'
                                                                        onClick={()=>this.goToDetailsScreen(
                                                                            item.researchTopic,item.researcherName,item.researchArea,item.researchStatus,
                                                                            item.pdfFile,
                                                                            item.researchAbstract,
                                                                            item.researchMainObjectives,
                                                                            item.researchSpecificObjectives,
                                                                            item.researchHypothesis,
                                                                            item.researchTheoreticalFrame,
                                                                            item.researchLiteratureReview,
                                                                            item.researchMethodology,
                                                                            item.researchResults,
                                                                            item.researchDiscussionOfResults,
                                                                            item.researchRecommendations,
                                                                            item.researchConclusions,
                                                                            item.researchArea,
                                                                            item.researchStatus
                                                                        )}/>
                                                                </td>
                                                            </tr>
                                                            </tbody>
                                                        </table>
                                                        
                                                    </div>
                                            </div>
                                            <div className="card-body">
                                                <p className='text-black'>{item.researchTopic}</p>
                                            </div>
                                            <div className="card-footer">
                                            <p className='text-black' >Research Area  | <b>{getIdCategory(item.researchArea,this.state.AreasData)}</b> &nbsp;&nbsp;&nbsp;&nbsp; Research Type | <b>{item.researchType}</b> &nbsp;&nbsp;&nbsp;&nbsp; Uploaded Date | <b>{item.uploadDate}</b></p>
                                            </div>
                                        </div>
                                        
                                    </div>
                                ))}
                            </div>

                            </div>

                        </div>
                    </div>

                        <div className="col-12">
                            <div className="card recent-sales overflow-auto">
                                <div className="card-body main-card">
                                <h5 className="card-title text-primary text-warning">Published Uploads </h5>
                                <div className='dashboard-view-more' >
                                    <button className='btn btn-outline-dark' onClick={()=>this.goToViewMore(this.state.PublishedData, "Published")} >
                                    <span className='text-warning'>View More</span> &nbsp;&nbsp;
                                    <i className="bi bi-arrow-right-square-fill text-warning view-more-bi"></i>
                                </button>
                                </div>  
                                <div className='breaking-space-div'>
                                    {PublishedData && PublishedData.slice(0,5).map((item, index)=>(
                                        <div key={index}>
                                            <div className="card research-display-card">
                                                <div className="card-header">
                                                    <p className="log-green-text">{convertToUpperCase(this.state.userName)}</p>
                                                        <div  className="card-details-div">
                                                            <table> 
                                                                <tbody>
                                                                <tr>
                                                                    <td>
                                                                        <p className="log-green-text details-label">Details</p>
                                                                    </td>
                                                                    <td>
                                                                        <img  src={NextIcon} alt='action btn img' className='card-next-icon'
                                                                            onClick={()=>this.goToDetailsScreen(
                                                                                item.researchTopic,item.researcherName,item.researchArea,item.researchStatus,
                                                                                item.pdfFile,
                                                                                item.researchAbstract,
                                                                                item.researchMainObjectives,
                                                                                item.researchSpecificObjectives,
                                                                                item.researchHypothesis,
                                                                                item.researchTheoreticalFrame,
                                                                                item.researchLiteratureReview,
                                                                                item.researchMethodology,
                                                                                item.researchResults,
                                                                                item.researchDiscussionOfResults,
                                                                                item.researchRecommendations,
                                                                                item.researchConclusions,
                                                                                item.researchArea,
                                                                                item.researchStatus
                                                                            )}/>
                                                                    </td>
                                                                </tr>
                                                                </tbody>
                                                            </table>
                                                            
                                                        </div>
                                                </div>
                                                <div className="card-body">
                                                    <p className='text-black'>{item.researchTopic}</p>
                                                </div>
                                                <div className="card-footer">
                                                <p className='text-black' >Research Area  | <b>{getIdCategory(item.researchArea,this.state.AreasData)}</b> &nbsp;&nbsp;&nbsp;&nbsp; Research Type | <b>{item.researchType}</b> &nbsp;&nbsp;&nbsp;&nbsp; Uploaded Date | <b>{item.uploadDate}</b></p>
                                                </div>
                                            </div>
                                            
                                        </div>
                                    ))}
                                </div>
                                </div>

                            </div>
                        </div>

                        <div className="col-12">
                            <div className="card recent-sales overflow-auto">
                                <div className="card-body main-card">
                                <h5 className="card-title text-warning">Working Progress Uploads </h5>
                                <div className='dashboard-view-more' >
                                    <button className='btn btn-outline-dark' onClick={()=>this.goToViewMore(this.state.WorkingPublishedData, "Working Progress")} >
                                        <span className='text-warning'>View More</span> &nbsp;&nbsp;
                                        <i className="bi bi-arrow-right-square-fill text-warning view-more-bi"></i>
                                    </button>
                                </div>
                                <div className='breaking-space-div'>
                                    {WorkingPublishedData && WorkingPublishedData.slice(0,5).map((item, index)=>(
                                        <div key={index}>
                                            <div className="card research-display-card">
                                                <div className="card-header">
                                                    <p className="log-green-text">{convertToUpperCase(this.state.userName)}</p>
                                                        <div  className="card-details-div">
                                                            <table>
                                                                <tbody>
                                                                <tr>
                                                                    <td>
                                                                        <p className="log-green-text details-label">Details</p>
                                                                    </td>
                                                                    <td>
                                                                        <img  src={NextIcon} alt='action btn img' className='card-next-icon'
                                                                            onClick={()=>this.goToDetailsScreen(
                                                                                item.researchTopic,item.researcherName,item.researchArea,item.researchStatus,
                                                                                item.pdfFile,
                                                                                item.researchAbstract,
                                                                                item.researchMainObjectives,
                                                                                item.researchSpecificObjectives,
                                                                                item.researchHypothesis,
                                                                                item.researchTheoreticalFrame,
                                                                                item.researchLiteratureReview,
                                                                                item.researchMethodology,
                                                                                item.researchResults,
                                                                                item.researchDiscussionOfResults,
                                                                                item.researchRecommendations,
                                                                                item.researchConclusions,
                                                                                item.researchArea,
                                                                                item.researchStatus
                                                                            )}/>
                                                                    </td>
                                                                </tr>
                                                                </tbody>
                                                            </table>
                                                            
                                                        </div>
                                                </div>
                                                <div className="card-body">
                                                    <p className='text-black'>{item.researchTopic}</p>
                                                </div>
                                                <div className="card-footer">
                                                <p className='text-black' >Research Area  | <b>{getIdCategory(item.researchArea,this.state.AreasData)}</b> &nbsp;&nbsp;&nbsp;&nbsp; Research Type | <b>{item.researchType}</b> &nbsp;&nbsp;&nbsp;&nbsp; Uploaded Date | <b>{item.uploadDate}</b></p>
                                                </div>
                                            </div>
                                            
                                        </div>
                                    ))}
                                </div>
                            </div>
                            </div>
                        </div>
                </div>
                {/* End col */}
            
            </>):(<> <GuestUser/><br></br></>)}
            </div>
        </div>

);
}}


function withNavigation(Component) {
    return function WrappedComponent(props) {
        const navigate = useNavigate();
        return <Component {...props} navigate={navigate} />;
    };
}

const mapDispatchToProps = (dispatch)=>{
    return{
            // Make API Calls
            fetchResearchAreasData: () => dispatch(fetchResearchAreasData()),
            fetchUniversitiesData: () => dispatch(fetchUniversitiesData())
    }
}

// Wrap the class component with the functional component to get access to navigate
export default connect (mapDispatchToProps, mapDispatchToProps)(withNavigation(Dashboard));