

import React,{Component} from 'react';
import axios from 'axios';

import { Link, useLocation, useNavigate, useParams } from 'react-router-dom';
import Col from 'react-bootstrap/Col';
import Nav from 'react-bootstrap/Nav';
import Row from 'react-bootstrap/Row';
import Tab from 'react-bootstrap/Tab';
import ButtonGroup from 'react-bootstrap/ButtonGroup';

import '../../Utilities/css/main.css';
import '../../Utilities/assets/bootstrap/css/bootstrap.min.css';
import '../../Utilities/assets/css/style.css';
import { APIResearchersList, AUTH_TOKEN, } from '../../Utilities/APIS';
import { daysPassed, getIdCategory, getIdFullName } from '../../Utilities/Functions';
import NavigationMenu from '../Navigation/NavigationMenu';
const headers = {'Authorization': `Bearer ${AUTH_TOKEN}`};

class Details extends Component {
    
constructor(props){
    
    super(props);
    this.state = {
        ResearchersData:[],
        UniversitiesData:[],
    }    
}

componentDidMount(){
    this.loadData();
    this.initializeReduxData();
}

initializeReduxData = () =>{
    const storedState = localStorage.getItem('reduxState');
    if (storedState) {
        const parsedState = JSON.parse(storedState);
        this.setState({ UniversitiesData: parsedState.sliceUniversities.UniversitiesData});

    }
};

loadData = () =>{

    axios.get(APIResearchersList,{headers})
    .then(res => {
        let results =JSON.stringify(res.data);
        let jsonResults =JSON.parse(results); 
        this.setState({ResearchersData:jsonResults})
        })
    .catch(err=>{console.log(err);})
}

render() {
// const {} =this.state;
    const { location } = this.props.router;
    const { state } = location;
    const { 
            researcherName,uploadDate,projectEnd,projectStart,universityName,
            projectTitle,projectFunders,projectDescription,projectMembers
            
        } = state || {};

return (
            <div className='body-container'>
                <NavigationMenu/>
                <div className='inner-body-container'>
                    <div className="alert alert-success bg-success text-light border-0 alert-dismissible" role="alert">
                        <strong className='strong-text white-text' >Project Details  </strong><span className='strong-text white-text'>View</span>
                        <div className="custom-alert-btn-actions">
                            <ButtonGroup className="mb-2">
                            
                                <Link to={"/projectsProjects"} >
                                    <button type='button' className='btn btn-dark black-bg-color'> Projects</button>
                                </Link>
                            </ButtonGroup>
                            </div>
                    </div>

                    <div className="col-12">
                        <div className="card recent-sales overflow-auto">
                            <div className="card-body">
                                <Tab.Container id="left-tabs-example" defaultActiveKey="Researcher" >
                                    <Row>
                                        <Col sm={2}>
                                        <Nav variant="pills" className="flex-column w3-pale-blue">
                                            <Nav.Item>
                                            <Nav.Link eventKey="Researcher">Author</Nav.Link>
                                            </Nav.Item>
                                            <Nav.Item>
                                            <Nav.Link eventKey="Topic">Title</Nav.Link>
                                            </Nav.Item>
                                            <Nav.Item>
                                            <Nav.Link eventKey="Abstract">Description</Nav.Link>
                                            </Nav.Item>
                                            <Nav.Item>
                                            <Nav.Link eventKey="MainObjectives">Funders</Nav.Link>
                                            </Nav.Item>
                                            <Nav.Item>
                                            <Nav.Link eventKey="SpecificObjectives">Other Members</Nav.Link>
                                            </Nav.Item>
                                        </Nav>
                                        </Col>
                                        <Col sm={9}>
                                        <Tab.Content>
                                            {/* <div className="w3-pale-blue "></div> */}
                                            <Tab.Pane eventKey="Researcher">
                                                <center>
                                                    <div className="card-body profile-card pt-4 d-flex flex-column align-items-center">
                                                        {/* <img src={} alt="Profile" class="rounded-circle"/> */}
                                                    <p className='research-preview-title primary-text'>Author</p>
                                                        <h6>{getIdFullName (researcherName, this.state.ResearchersData)}</h6>
                                                    </div>
                                                </center>
                                            </Tab.Pane>

                                            <Tab.Pane eventKey="Topic">
                                                <p className='research-preview-title primary-text'>Title</p>
                                                <p className='research-preview-paragraph primary-text'>{projectTitle}</p>
                                                {/* <h6 className='research-preview-paragraph primary-text'>{getIdCategory(researchArea,this.state.AreasData)}</h6> */}
                                                <p className="">{getIdCategory (universityName,this.state.UniversitiesData)} University</p>
                                                <p className="mb-0">&nbsp;&nbsp;&nbsp; Start Period <b>{projectStart}</b> &nbsp;&nbsp;&nbsp; End Period <b>{projectEnd}</b></p>
                                                <span className='log-green-text'>{daysPassed (uploadDate)} days ago</span>

                                            </Tab.Pane>
                                            <Tab.Pane eventKey="Abstract">
                                                <p className='research-preview-title primary-text'>Description</p>
                                                <p>{projectDescription}</p>

                                            </Tab.Pane>
                                            <Tab.Pane eventKey="MainObjectives">
                                                <p className='research-preview-title primary-text'>Funders</p>
                                                {projectFunders && JSON.parse(projectFunders).map((item,index)=>(
                                                    <div key={index}>
                                                        <div >
                                                            <p className='primary-text research-preview-paragraph'>{item.data}</p>
                                                        </div>
                                                        
                                                    </div>
                                                ))}
                                            </Tab.Pane>
                                            <Tab.Pane eventKey="SpecificObjectives">
                                                <p className='research-preview-title primary-text'>Other Members</p>
                                                {projectMembers && JSON.parse(projectMembers).map((item,index)=>(
                                                <div key={index}>
                                                    <div >
                                                        <p className='primary-text research-preview-paragraph'>{item.data}</p>
                                                    </div>
                                                    
                                                </div>
                                            ))}
                                            </Tab.Pane>

                                        </Tab.Content>
                                        </Col>
                                    </Row>
                                </Tab.Container>
                            </div>
                        </div>
                    </div>
                </div>
                {/* <!-- End Recent Sales --> */}
        </div>
);
}}

// This is a higher-order component to pass hooks to class components
function withRouter(Component) {
    function ComponentWithRouterProp(props) {
        let location = useLocation();
        let navigate = useNavigate();
        let params = useParams();
        return (
        <Component
            {...props}
            router={{ location, navigate, params }}
        />
        );
    }

    return ComponentWithRouterProp;
}
export default withRouter(Details);