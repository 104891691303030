


import React,{Component} from 'react';
import axios from 'axios';
import { useNavigate} from 'react-router-dom';

import '../Utilities/css/main.css';
import '../Utilities/assets/bootstrap/css/bootstrap.min.css';
import '../Utilities/assets/css/style.css';
import { APIResearcherPasswordRest, AUTH_TOKEN } from '../Utilities/APIS';
import { ERROR_ALL_INPUTS_REQUIRED, ERROR_POST, PasswordMisMatch, validateEmailText, ValidPasswordText } from '../Utilities/Errors';
import { passwordRegex } from '../Utilities/Constants';

const headers = {'Authorization': `Bearer ${AUTH_TOKEN}`};

class ResetPassword extends Component {
    
constructor(props){
    
    super(props);
    this.state = {
        Password:'',
        PasswordRetype:'',
        UniversitiesData:'',
        PasswordDoNotMatch:'',

        Email:'',
        emailError:'',
        PasswordResetResponse:'',
        AllInputsRequired:'',
        ValidPassword:'',
    }    
}

componentDidMount(){

}

setPasswordTextInput = (text) =>{this.setState({Password:text.target.value})}
setPasswordRetypeTextInput = (text) =>{this.setState({PasswordRetype:text.target.value})}
setEmailTextInput = (text) =>{this.setState({Email:text.target.value})}


validateEmail = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
};

userPasswordReset = async () =>{
    // const token = new URLSearchParams(window.location.search).get("token");
    const queryParams = new URLSearchParams(window.location.search);
    const token = queryParams.get('token');
    
    let password = this.state.Password
    let passwordRetype = this.state.PasswordRetype
    let email = this.state.Email

    if ( password.length === 0 || email.length === 0)
    {
        this.setState({AllInputsRequired:ERROR_ALL_INPUTS_REQUIRED}); 
    }
    else
    {
        if (this.validateEmail(email)) 
            {
                if (!passwordRegex.test(password)) {
                    this.setState({ValidPassword:ValidPasswordText});
                }
                else 
                {
                    if (password !== passwordRetype)
                        { this.setState({PasswordDoNotMatch:PasswordMisMatch}); }

                    else
                        {
                            try
                                {
                                    const postRequest = await axios.post(APIResearcherPasswordRest,{
                                        "token": token, 
                                        "email": email, 
                                        "password":password,
                                    },{headers})
                                    
                                    let result = postRequest.data;
                                    this.setState({PasswordResetResponse:result})
                                }
                                catch (error)
                                    {
                                        console.log("===========>>>>>>>>>>"+error)
                                        alert(ERROR_POST)
                                    };
                        }
                }
            } 
            else {this.setState({emailError:validateEmailText});}
    }
}

        
goToLogIn = () =>{this.props.navigate("/");}

render() {
const {Password,PasswordRetype,PasswordDoNotMatch,Email,emailError,PasswordResetResponse,ValidPassword,AllInputsRequired} =this.state;
return (
        <main className="main-content  mt-0">
            <div className="page-header align-items-start min-vh-100">
                <span className="mask bg-gradient-dark opacity-6"></span>
                    <div className="container my-auto">
                        <div className="row">
                            <div className="col-lg-4 col-md-8 col-12 mx-auto">
                                <br/><br/><br/><br/>
                                <div className="card z-index-0 fadeIn3 fadeInBottom">
                                    <div className="card-body">
                                        <center>
                                            {/* <br/>
                                            <img src={Logo} alt='logo-icon' className="logo-icon" /> */}
                                            <br/>
                                            {/* <h4><b> Forgot Password !</b></h4> */}
                                            <h5 className='custom-red-color'><b>RIA</b></h5>
                                            <h5 className='custom-red-color'><b>Reset Password !</b></h5>
                                            {/* <h5 className='custom-red-color' ><b>RIA</b></h5> */}
                                            <h6><b>Enter New Password </b></h6>
                                        </center>
                                        <div >
                                            <label className="primary-text">Email *</label>
                                            <input type="text"  className="form-control form-inputs"  autoComplete="off" placeholder="Email"
                                                value={Email}
                                                onChange={text => this.setEmailTextInput(text)}
                                            />
                                            <p className="primary-text red-text" >{emailError}</p>
                                        </div>
                                        <div >
                                            <label className="primary-text">Password</label>
                                            <input type="password"  className="form-control form-inputs" autoComplete="off" placeholder="**************"
                                                value={Password}
                                                onChange={text => this.setPasswordTextInput(text)}
                                            />
                                            <p className="primary-text red-text" >{PasswordDoNotMatch}</p>
                                            <p className="primary-text red-text">{ValidPassword}</p>
                                        </div>
                                        <div >
                                            <label className="primary-text">Confirm Password</label>
                                            <input type="password"  className="form-control form-inputs" autoComplete="off" placeholder="**************" 
                                                value={PasswordRetype}
                                                onChange={text => this.setPasswordRetypeTextInput(text)}
                                            />
                                            <p className="primary-text red-text" >{PasswordDoNotMatch}</p>
                                            <p className="primary-text red-text">{PasswordResetResponse}</p>
                                            <p className="primary-text red-text">{AllInputsRequired}</p>
                                            
                                        </div>
                                        <div className="text-center">
                                        <button onClick={()=>this.userPasswordReset()} className="btn btn-primary custom-bg-primary custom-text-white" style={{width:"80%"}}> Reset Password </button>

                                        <br/><br/>
                                        <center><p><b>-- OR --</b></p></center>
                                        <button onClick={()=>this.goToLogIn()} className="btn btn-success custom-bg-primary custom-text-white" style={{width:"80%"}}> Log In </button>
                                        </div><br/>

                                    </div>
                                </div>
                            </div>
                        </div>
                </div>
            </div>
        </main>
);
}}

function withNavigation(Component) {
    return function WrappedComponent(props) {
        const navigate = useNavigate();
        return <Component {...props} navigate={navigate} />;
    };
}



// Wrap the class component with the functional component to get access to navigate
export default withNavigation(ResetPassword);