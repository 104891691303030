import { useNavigate, useLocation, useParams } from 'react-router-dom';

// HOC to pass navigate, location, and params to a class component
function withNavigate(Component) {
    return function ComponentWithRouterProp(props) {
        const navigate = useNavigate();
        const location = useLocation();
        const params = useParams();
        
        // Pass navigate, location, and params directly as props
        return <Component {...props} navigate={navigate} location={location} params={params} />;
    };
}

export default withNavigate;
