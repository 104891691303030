

import React,{Component} from 'react';
import Alert from 'react-bootstrap/Alert';
import { Link, useLocation, useNavigate, useParams } from 'react-router-dom';
import '../Utilities/css/main.css';
import '../Utilities/assets/bootstrap/css/bootstrap.min.css';
import '../Utilities/assets/css/style.css';


class GuestDashboard extends Component {
    
constructor(props){
    
    super(props);
    this.state = {
    }    
}

componentDidMount(){
}

render() {
// const {} =this.state;

return (
        <div className='body-container'>
            <div className='inner-body-container'>
                <div className="col-12">
                    <div className="card recent-sales overflow-auto">
                        <div className="card-body">
                            
                                <Alert variant="danger">
                                    <Alert.Heading><b>Welcome Come Our Guest !!</b></Alert.Heading>
                                        <p>
                                            Login If You Have Already Registered With RIAUG For More Features
                                        </p>
                                    <hr />
                                    <p className="mb-0">Guest Users Have Less Features On The Application </p>
                                </Alert>
                        </div>
                        <center>
                            <table>
                                <tbody>
                                    <tr>
                                        <td>
                                            <Link to={'/'} >
                                                <button  className="btn btn-primary custom-bg-primary custom-text-white" > Log In Now</button>
                                            </Link>
                                        </td>
                                        <td>
                                            &nbsp;&nbsp;
                                            <Link to={'/register'} >
                                                <button  className="btn btn-success custom-bg-primary custom-text-white" > Register Now</button>
                                            </Link>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                            <br></br>
                        </center>
                    </div>
                </div>
            </div>
        </div>
);
}}

// This is a higher-order component to pass hooks to class components
function withRouter(Component) {
    function ComponentWithRouterProp(props) {
        let location = useLocation();
        let navigate = useNavigate();
        let params = useParams();
        return (
        <Component
            {...props}
            router={{ location, navigate, params }}
        />
        );
    }

    return ComponentWithRouterProp;
}
export default withRouter(GuestDashboard);